import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./visa.css";

const Visa = () => {
  const [personalDetails, setPersonalDetails] = useState("");
  const [visaType, setVisaType] = useState("");
  const [intakeType, setIntakeType] = useState("");
  const [qualification, setQualification] = useState("");
  const [visitingCountry, setVisitingCountry] = useState("");

  const navigate = useNavigate();

  const upload = async () => {
    try {
      const requestData = {
        personal_details: personalDetails,
        type_of_visa: visaType,
        intake_type: intakeType,
        qualification: qualification,
        visiting_country: visitingCountry
      };

      const response = await fetch("https://api.my-friend.co/ovs_form/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      });

      if (response.ok) {
        toast.success("Data uploaded successfully!");
        navigate("/home");
      } else {
        throw new Error("Failed to upload data");
      }
    } catch (error) {
      console.error("Error uploading data:", error);
      toast.error("Failed to upload data");
    }
  };

  return (
    <div className="visa-page">
      <div className="visa-container">
        <h2>Visa Consultancy</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="visa-input-group">
            <label>Type of Visa</label>
            <select
              value={visaType}
              onChange={(e) => setVisaType(e.target.value)}
              required
            >
              <option value="">Select Visa Type</option>
              <option value="student">Student Visa</option>
              <option value="work">Work Visa</option>
              <option value="tourist">Tourist Visa</option>
              <option value="family">Family Visa</option>
            </select>
          </div>
          <div className="visa-input-group">
            <label>Intake Type</label>
            <select
              value={intakeType}
              onChange={(e) => setIntakeType(e.target.value)}
              required
            >
              <option value="">Select Intake Type</option>
              <option value="fall">Fall (Aug - Dec)</option>
              <option value="spring">Spring (Jan - April)</option>
              <option value="summer">Summer (May - Aug)</option>
            </select>
          </div>
          <div className="visa-input-group">
            <label>Qualification</label>
            <select
              value={qualification}
              onChange={(e) => setQualification(e.target.value)}
              required
            >
              <option value="">Select Qualification</option>
              <option value="bachelors">Bachelors Degree</option>
              <option value="masters">Masters Degree</option>
              <option value="bba">BBA</option>
              <option value="pharma">Bachelors in Pharma</option>
              <option value="other">Other courses (Enter details)</option>
            </select>
          </div>
          <div className="visa-input-group">
            <label>Visiting Country</label>
            <select
              value={visitingCountry}
              onChange={(e) => setVisitingCountry(e.target.value)}
              required
            >
              <option value="">Select Visiting Country</option>
              <option value="uk">United Kingdom</option>
              <option value="us">United States</option>
              <option value="canada">Canada</option>
              <option value="australia">Australia</option>
              <option value="germany">Germany</option>
              <option value="france">France</option>
            </select>
          </div>
          <button className="visa-button" type="button" onClick={upload}>
            Submit
          </button>
          <p className="visa-user">
            Enter correct details{" "}
            <span>
              <b>Home page</b>
            </span>
          </p>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Visa;
