import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import App from './App';
import About from './About';
import Home from './Home';
import Contact from './Contact';
import Services from './Services';
import LegalSupport from './Legal_support'; // Ensure consistent naming convention
import Payment from './Payment';
import Careers from './Careers';
import LatestOffers from './Latestoffers';
import Universities from './Universities';
import Cuser from './cuser';
import Accommodation from './accomadation/accomadation'; // Correct typo in 'Accommodation'
import Jobs from './jobd/jobs';
import Visa from './visa/visa';
import Local from './local/local';
import Travelling from './travelling/travelling';
import AcademicCorner from './acedemiccorner/acedemiccorner'; // Ensure consistent naming convention
import School from './school/school';
import OnBoard from './onboard/onboard'; // Ensure consistent naming convention
import Privacy from './privacy';
import Courses from './Courses';
import Airport from './airport/airport';
import TicketBooking from './ticketbooking'; // Ensure consistent naming convention
import LocalShopping from './localshopping'; // Ensure consistent naming convention
import LoginRe from './loginre'; // Ensure consistent naming convention
import Profile from './Profile/Profile';

import Register from './register/register';
import Reset from './reset';
import Otp from './otp';
import JobApplicationForm  from "./mfcjobs";
import Country from './country';

const Root = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Services />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/latestoffers" element={<LatestOffers />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/universities" element={<Universities />} />
        <Route path="/accommodation" element={<Accommodation />} /> {/* Corrected the typo */}
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/legalsupport" element={<LegalSupport />} /> {/* Consistent naming */}
        <Route path="/visa" element={<Visa />} />
        <Route path="/airport" element={<Airport />} />
        <Route path="/local" element={<Local />} />
        <Route path="/travelling" element={<Travelling />} />
        <Route path="/courses/:universityId" element={<Courses />} />
        <Route path="/academiccorner" element={<AcademicCorner />} /> {/* Consistent naming */}
        <Route path="/school" element={<School />} />
        <Route path="/onboard" element={<OnBoard />} /> {/* Consistent naming */}
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/ticketbooking" element={<TicketBooking />} /> {/* Consistent naming */}
        <Route path="/localshopping" element={<LocalShopping />} /> {/* Consistent naming */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/loginre" element={<LoginRe />} /> {/* Consistent naming */}
        
        <Route path="/register" element={<Register />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/country" element={<Country/>} />
        <Route path="/app" element={<App />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/mfcjobs " element={<JobApplicationForm />} />
      </Routes>
    </Router>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

export default Root;
