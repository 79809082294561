import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../App.css';

const Airport = () => {
  const [personalDetails, setPersonalDetails] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [arrivalLocation, setArrivalLocation] = useState("");
  const [timeOfArrival, setTimeOfArrival] = useState("");
  const [placeToVisit, setPlaceToVisit] = useState("");
  const [luggageSize, setLuggageSize] = useState("");
  const [numPassengers, setNumPassengers] = useState("");
  const [otherDetails, setOtherDetails] = useState("");

  const navigate = useNavigate();

  const upload = async () => {
    try {
      const requestData = {
        personal_details: personalDetails,
        arrival_date: arrivalDate,
        arrival_location: arrivalLocation,
        time_of_arrival: timeOfArrival,
        place_to_visit: placeToVisit,
        luggage_size: luggageSize,
        num_passengers: numPassengers,
        other_details: otherDetails
      };

      const response = await fetch("https://api.my-friend.co/ads_form/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      });

      if (response.ok) {
        toast("Data uploaded successfully!");
        navigate("/Home");
      } else {
        throw new Error("Failed to upload data");
      }
    } catch (error) {
      console.error("Error uploading data:", error);
      toast.error("Failed to upload data");
    }
  };

  return (
    <div className="login-page">
      <div className="login-contain">
        <h2>Airport Assistance</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="login-input-group">
            <label htmlFor="personalDetails">Personal Details</label>
            <input
              type="text"
              id="personalDetails"
              value={personalDetails}
              onChange={(e) => setPersonalDetails(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
            />
          </div>
          <div className="login-input-group">
            <label htmlFor="arrivalDate">Arrival Date</label>
            <input
              type="date"
              id="arrivalDate"
              value={arrivalDate}
              onChange={(e) => setArrivalDate(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
            />
          </div>
          <div className="login-input-group">
            <label htmlFor="arrivalLocation">Arrival Location</label>
            <input
              type="text"
              id="arrivalLocation"
              value={arrivalLocation}
              onChange={(e) => setArrivalLocation(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
            />
          </div>
          <div className="login-input-group">
            <label htmlFor="timeOfArrival">Time of Arrival</label>
            <input
              type="time"
              id="timeOfArrival"
              value={timeOfArrival}
              onChange={(e) => setTimeOfArrival(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
            />
          </div>
          <div className="login-input-group">
            <label htmlFor="placeToVisit">Place to Visit</label>
            <input
              type="text"
              id="placeToVisit"
              value={placeToVisit}
              onChange={(e) => setPlaceToVisit(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
            />
          </div>
          <div className="login-input-group">
            <label htmlFor="luggageSize">Luggage Size</label>
            <input
              type="text"
              id="luggageSize"
              value={luggageSize}
              onChange={(e) => setLuggageSize(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
            />
          </div>
          <div className="login-input-group">
            <label htmlFor="numPassengers">Number of Passengers</label>
            <input
              type="number"
              id="numPassengers"
              value={numPassengers}
              onChange={(e) => setNumPassengers(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
            />
          </div>
          <div className="login-input-group">
            <label htmlFor="otherDetails">Other Details</label>
            <input
              type="text"
              id="otherDetails"
              value={otherDetails}
              onChange={(e) => setOtherDetails(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
            />
          </div>
          <button className="login-button" type="button" onClick={upload}>
            Submit
          </button>
          <p className="login-links">
            Enter correct details{" "}
            <span>
              <b>Home page</b>
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Airport;
