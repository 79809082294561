import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Visa = () => {
  const [personalDetails, setPersonalDetails] = useState("");
  const [travelDate, setTravelDate] = useState("");
  const [fromLocation, setFromLocation] = useState("");
  const [timeOfContact, setTimeOfContact] = useState("");
  const [placesToVisit, setPlacesToVisit] = useState("");
  const [luggageSize, setLuggageSize] = useState("");
  const [numPassengers, setNumPassengers] = useState("");

  const navigate = useNavigate();

  const upload = async () => {
    try {
      const requestData = {
        personal_details: personalDetails,
        travel_date: travelDate,
        from_location: fromLocation,
        time_of_contact: timeOfContact,
        places_to_visit: placesToVisit,
        luggage_size: luggageSize,
        num_passengers: numPassengers
      };

      const response = await fetch("https://api.my-friend.co/tc_form/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      });

      if (response.ok) {
        toast("Data uploaded successfully!");
        navigate("/Home");
      } else {
        throw new Error("Failed to upload data");
      }
    } catch (error) {
      console.error("Error uploading data:", error);
      toast.error("Failed to upload data");
    }
  };

  return (
    <div class="login-page">
  <div class="login-contain">
    <h2>Travel Companion</h2>
    <form onSubmit={(e) => e.preventDefault()}>
      <div class="login-input-group">
        <label>Personal Details</label>
        <input
          type="text"
          value={personalDetails}
          onChange={(e) => setPersonalDetails(e.target.value)}
          required
          style={{ display: 'block', border: '1px solid black', padding: '10px' }}
        />
      </div>

      <div class="login-input-group">
        <label>Travel Date</label>
        <input
          type="date"
          value={travelDate}
          onChange={(e) => setTravelDate(e.target.value)}
          required
          style={{ display: 'block', border: '1px solid black', padding: '10px' }}
        />
      </div>

      <div class="login-input-group">
        <label>From Location</label>
        <input
          type="text"
          value={fromLocation}
          onChange={(e) => setFromLocation(e.target.value)}
          required
          style={{ display: 'block', border: '1px solid black', padding: '10px' }}
        />
      </div>

      <div class="login-input-group">
        <label>Time of Contact</label>
        <input
          type="text"
          value={timeOfContact}
          onChange={(e) => setTimeOfContact(e.target.value)}
          required
          style={{ display: 'block', border: '1px solid black', padding: '10px' }}
        />
      </div>

      <div class="login-input-group">
        <label>Places to Visit</label>
        <input
          type="text"
          value={placesToVisit}
          onChange={(e) => setPlacesToVisit(e.target.value)}
          required
          style={{ display: 'block', border: '1px solid black', padding: '10px' }}
        />
      </div>

      <div class="login-input-group">
        <label>Luggage Size</label>
        <input
          type="text"
          value={luggageSize}
          onChange={(e) => setLuggageSize(e.target.value)}
          required
          style={{ display: 'block', border: '1px solid black', padding: '10px' }}
        />
      </div>

      <div class="login-input-group">
        <label>Number of Passengers</label>
        <input
          type="text"
          value={numPassengers}
          onChange={(e) => setNumPassengers(e.target.value)}
          required
          style={{ display: 'block', border: '1px solid black', padding: '10px' }}
        />
      </div>

      <button type="button" class="login-button" onClick={upload}>
        Submit
      </button>

      <p class="message">
        Enter correct details{" "}
        <span>
          <b>Home page</b>
        </span>
      </p>
    </form>
  </div>
</div>
  );
};

export default Visa;
