import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Mfcjobs = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        personal_details: '',
        nature_of_job: '',
        profession_type: '',
        work_experience: '',
        language_known: '',
        ethnic_region: '',
        resume: '',
        extras: '',
        country_id: '',
        user_id: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await fetch('https://api.my-friend.co/job_form/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Something went wrong');
            }

            toast.success('Job application submitted successfully!');
            navigate('/profile', { state: { userId: formData.user_id } });

        } catch (error) {
            console.error('Error:', error);
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="register-container">
            <ToastContainer />
            <h2>Job Application</h2>
            <form onSubmit={handleSubmit}>
                <div className="register-input-group">
                    <label htmlFor="personal_details">Personal Details</label>
                    <input
                        type="text"
                        name="personal_details"
                        value={formData.personal_details}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="register-input-group">
                    <label htmlFor="nature_of_job">Nature of Job</label>
                    <input
                        type="text"
                        name="nature_of_job"
                        value={formData.nature_of_job}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="register-input-group">
                    <label htmlFor="profession_type">Profession Type</label>
                    <input
                        type="text"
                        name="profession_type"
                        value={formData.profession_type}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="register-input-group">
                    <label htmlFor="work_experience">Work Experience</label>
                    <input
                        type="text"
                        name="work_experience"
                        value={formData.work_experience}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="register-input-group">
                    <label htmlFor="language_known">Language Known</label>
                    <input
                        type="text"
                        name="language_known"
                        value={formData.language_known}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="register-input-group">
                    <label htmlFor="ethnic_region">Ethnic Region</label>
                    <input
                        type="text"
                        name="ethnic_region"
                        value={formData.ethnic_region}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="register-input-group">
                    <label htmlFor="resume">Resume</label>
                    <input
                        type="text"
                        name="resume"
                        value={formData.resume}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="register-input-group">
                    <label htmlFor="extras">Extras</label>
                    <input
                        type="text"
                        name="extras"
                        value={formData.extras}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="register-input-group">
                    <label htmlFor="country_id">Country ID</label>
                    <input
                        type="text"
                        name="country_id"
                        value={formData.country_id}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="register-input-group">
                    <label htmlFor="user_id">User ID</label>
                    <input
                        type="text"
                        name="user_id"
                        value={formData.user_id}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit" className="register-button" disabled={isLoading}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                </button>
            </form>
        </div>
    );
};

export default Mfcjobs;
