import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Local = () => {
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editableIndex, setEditableIndex] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://api.my-friend.co/localguide/1");
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const parsedData = JSON.parse(data);
      const fieldsData = parsedData.map((row) => row.fields);
      setCourse(fieldsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
      toast.error("Failed to fetch data");
      setLoading(false);
    }
  };

  // Function to handle delete action for a row
  const handleDelete = (index) => {
    // Implement delete action here
    // For example, you can call an API to delete the row
    // Once deleted, you can refresh the data or update the UI accordingly
    console.log("Delete row at index:", index);
  };

  // Function to handle edit action for a row
  const handleEdit = (index) => {
    setEditableIndex(index);
  };

  // Function to handle save action for a row
  const handleSave = (index) => {
    // Implement save action here
    // For example, you can call an API to save the edited row
    console.log("Save row at index:", index);
    setEditableIndex(null); // Disable editing after saving
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!course) {
    return <div>No course data available.</div>;
  }

  // Extract field names from the first row
  const fieldNames = Object.keys(course[0]);

  return (
    <div className="excel-container">
      {/* Render each row as a separate box */}
      {course.map((row, index) => (
        <div className="table-row" key={index}>
          {/* Render image cell */}
          <div className="table-cell">
            <img src={`https://api.my-friend.co/media/uni/${row.country}/${row.image1}`} alt="University Image" />
          </div>
          {/* Render separate cells for other fields */}
          {fieldNames.map((fieldName) => (
            <div className="table-cell" key={fieldName}>
              {editableIndex === index ? (
                <input
                  type="text"
                  value={row[fieldName]}
                  onChange={(e) => {
                    // Implement handleChange for each input field
                  }}
                />
              ) : (
                row[fieldName]
              )}
            </div>
          ))}
          {/* Render action cell */}
          <div className="table-cell">
            <button onClick={() => handleEdit(index)}>Edit</button>
            <button onClick={() => handleDelete(index)}>Delete</button>
            {editableIndex === index && (
              <button onClick={() => handleSave(index)}>Save</button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Local;
