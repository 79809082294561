import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './register.css';

const Register = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    middle_name: '',
    sur_name: '',
    mobile_number: '',
    password: '',
    email: '',
    address: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://api.my-friend.co/register/', formData)
      .then(response => {
        console.log('Registration successful:', response.data);
        // Save email to local storage
        localStorage.setItem('registeredEmail', formData.email);
        // Navigate to the reset page
        navigate('/reset');
      })
      .catch(error => {
        console.error('There was an error registering:', error);
        // Handle registration error
      });
  };

  return (
    <div className="register-container">
      <h2>Register</h2>
      <form onSubmit={handleSubmit}>
        <div className="register-input-group">
          <label>First Name:</label>
          <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                    style={{ display: 'block', border: '1px solid black', padding: '8px', width: '100%' }}

                />
        </div>
        <div className="register-input-group">
          <label>Middle Name:</label>
          <input type="text" name="middle_name" value={formData.middle_name} onChange={handleChange} required
                    style={{ display: 'block', border: '1px solid black', padding: '8px', width: '100%' }}/>
        </div>
        <div className="register-input-group">
          <label>Surname:</label>
          <input type="text" name="sur_name" value={formData.sur_name} onChange={handleChange} required 
           style={{ display: 'block', border: '1px solid black', padding: '8px', width: '100%' }}/>
        </div>
        <div className="register-input-group">
          <label>Mobile Number:</label>
          <input type="text" name="mobile_number" value={formData.mobile_number} onChange={handleChange} required 
           style={{ display: 'block', border: '1px solid black', padding: '8px', width: '100%' }}/>
        </div>
        <div className="register-input-group">
          <label>Password:</label>
          <input type="password" name="password" value={formData.password} onChange={handleChange} required
           style={{ display: 'block', border: '1px solid black', padding: '8px', width: '100%' }} />
        </div>
        <div className="register-input-group">
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required 
           style={{ display: 'block', border: '1px solid black', padding: '8px', width: '100%' }}/>
        </div>
        <div className="register-input-group">
          <label>Address:</label>
          <input type="text" name="address" value={formData.address} onChange={handleChange} 
           style={{ display: 'block', border: '1px solid black', padding: '8px', width: '100%' }}/>
        </div>
        <button type="submit" className="register-button">Register</button>
      </form>
    </div>
  );
};

export default Register;

