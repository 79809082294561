import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './reset.css';
import { useNavigate } from 'react-router-dom';
const Sendotp= () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const registeredEmail = localStorage.getItem('registeredEmail');
    if (registeredEmail) {
      setEmail(registeredEmail);
    }
  }, []);

  const requestOtp = async () => {
    try {
      await axios.post(`https://api.my-friend.co/request-otp/${email}/`);
      setMessage('OTP requested successfully.Please check your email.');
      navigate('/otp');
    } catch (error) {
      setMessage(`Failed to request OTP. ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '400px', margin: '0 auto', fontFamily: 'Arial, sans-serif' }}>
      <h2>Request OTP</h2>
      <div style={{ marginBottom: '10px' }}>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            localStorage.setItem('registeredEmail', e.target.value);
          }}
          style={{ width: '100%', padding: '8px', margin: '5px 0' }}
        />
      </div>
      <button onClick={requestOtp} style={{ padding: '10px' }}>Request OTP</button>
      {message && <p style={{ marginTop: '20px' }}>{message}</p>}
    </div>
  );
};

export default Sendotp;
