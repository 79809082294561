import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useParams } from 'react-router-dom';

const Courses = () => {
const params = useParams();
const  uid  = params.universityId;
  const [courses, setCourses] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editableIndex, setEditableIndex] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`https://api.my-friend.co/courses/${uid}`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const parsedData = JSON.parse(data);
      const fieldsData = parsedData.map((row) => {
        const filteredRow = { ...row.fields };
        delete filteredRow.latitude;
        delete filteredRow.longitude;
        delete filteredRow.status; // Remove 'status' field
        delete filteredRow.rank; // Remove 'rank' field
        return filteredRow;
      });
      setCourses(fieldsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
      toast.error("Failed to fetch data");
      setLoading(false);
    }
  };

  const handleDelete = (index) => {
    console.log("Delete row at index:", index);
  };

  const handleEdit = (index) => {
    setEditableIndex(index);
  };

  const handleSave = (index) => {
    console.log("Save row at index:", index);
    setEditableIndex(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!courses) {
    return <div>No course data available.</div>;
  }

  const fieldLabels = {
    name: "Name",
    description: "Description",
    highlights: "Highlights",
    admission: "Admission",
    prerequisites: "Prerequisites",
    fee: "Fee"
  };

  const fieldNames = Object.keys(courses[0]);
 
  return (
    <div className="excel-container">
      {courses.map((row, index) => (
        <div className="table-row" key={index}>
          <div className="content-cell">
            {fieldNames.map((fieldName) => (
              fieldName !== 'image1' && fieldName !== 'image2' && fieldName !== 'status' && fieldName !== 'rank' ? ( // Check if the field name is not an image or excluded field
                <div className="table-cell" key={fieldName}>
                  {fieldName === 'name' ? ( // Check if the field name is 'name'
                    <strong style={{ display: 'block', textAlign: 'center' }}>{row[fieldName]}</strong> // Wrap name with <strong>
                  ) : (
                    editableIndex === index ? (
                      <input
                        type="text"
                        value={row[fieldName]}
                        onChange={(e) => {}}
                      />
                    ) : (
                      <div>
                        <span><strong>{fieldLabels[fieldName]}:</strong></span> {row[fieldName]}
                      </div>
                    )
                  )}
                </div>
              ) : null
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Courses;
