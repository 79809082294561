// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import "./loginre.css"; // Make sure to import your CSS file

// const Loginre = () => {
//   const navigate = useNavigate();

//   const handleRegisterClick = () => {
//     const container = document.getElementById('container');
//     container.classList.add("active");
//   };

//   const handleLoginClick = () => {
//     const container = document.getElementById('container');
//     container.classList.remove("active");
//   };

//   const handleSignInSubmit = async (event) => {
//     event.preventDefault();
//     const email = document.getElementById('login_email').value;
//     const password = document.getElementById('login_password').value;

//     try {
//       const response = await axios.post('https://api.my-friend.co/login/', {
//         email: email,
//         password: password,
//       });
//       console.log(response.data); // Handle success response
//       // Redirect to home page after successful sign in
//       navigate('/home');
//     } catch (error) {
//       console.error('Error signing in:', error); // Handle error
//     }
//   };

//   return (
//     <div className="container" id="container">
//       <div className="form-container sign-up">
//         <SignUpForm />
//       </div>
//       <div className="form-container sign-in">
//         <form onSubmit={handleSignInSubmit}>
//           <h1>Sign In</h1>
//           <div className="social-icons">
//             <a href="#" className="icon"><i className="fa-brands fa-google-plus-g"></i></a>
//             <a href="#" className="icon"><i className="fa-brands fa-facebook-f"></i></a>
//             <a href="#" className="icon"><i className="fa-brands fa-github"></i></a>
//             <a href="#" className="icon"><i className="fa-brands fa-linkedin-in"></i></a>
//           </div>
//           <span>or use your account</span>
//           <input type="email" id="login_email" placeholder="Email" />
//           <input type="password" id="login_password" placeholder="Password" />
//           <button type="submit">Sign In</button>
//         </form>
//       </div>
//       <div className="toggle-container">
//         <div className="toggle">
//           <div className="toggle-panel toggle-left">
//             <h1>Welcome Back!</h1>
//             <p>Enter your personal details to use all of site features</p>
//             <button className="hidden" id="login" onClick={handleLoginClick}>Sign In</button>
//           </div>
//           <div className="toggle-panel toggle-right">
//             <h1>Hello, Friend!</h1>
//             <p>Register with your personal details to use all of site features</p>
//             <button className="hidden" id="register" onClick={handleRegisterClick}>Sign Up</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const SignUpForm = () => {
//   const [formData, setFormData] = useState({
//     first_name: '',
//     middle_name: '',
//     sur_name: '',
//     mobile_number: '',
//     password: '',
//     email: '',
//     address: ''
//   });

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.id]: e.target.value
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch('https://api.my-friend.co/register/', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(formData)
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const data = await response.json();
//       console.log('Success:', data);
//       // Handle successful response, e.g., redirect or show success message
//     } catch (error) {
//       console.error('Error:', error);
//       // Handle error, e.g., show error message
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <h1>Create Account</h1>
//       <div className="social-icons">
//         <a href="#" className="icon"><i className="fa-brands fa-google-plus-g"></i></a>
//         <a href="#" className="icon"><i className="fa-brands fa-facebook-f"></i></a>
//         <a href="#" className="icon"><i className="fa-brands fa-github"></i></a>
//         <a href="#" className="icon"><i className="fa-brands fa-linkedin-in"></i></a>
//       </div>
//       <span>or use your email for registration</span>
//       <input type="text" id="first_name" placeholder="Name" value={formData.first_name} onChange={handleChange} />
//       <input type="text" id="middle_name" placeholder="middle name" value={formData.middle_name} onChange={handleChange} />
//       <input type="text" id="sur_name" placeholder="sur name" value={formData.sur_name} onChange={handleChange} />
//       <input type="tel" id="mobile_number" placeholder="mobile number" value={formData.mobile_number} onChange={handleChange} />
//       <input type="password" id="password" placeholder="Password" value={formData.password} onChange={handleChange} />
//       <input type="email" id="email" placeholder="email" value={formData.email} onChange={handleChange} />
//       <input type="text" id="address" placeholder="Address" value={formData.address} onChange={handleChange} />
//       <button type="submit">Sign Up</button>
//     </form>
//   );
// };

// export default Loginre;
// import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom'; 

// import "./loginre.css";

// const Loginre = () => {
//     const history = useHistory();

//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [error, setError] = useState(null);
//     const [isLoading, setIsLoading] = useState(false);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsLoading(true);
//         setError(null);

//         try {
//             const response = await fetch('https://api.my-friend.co/login/', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify({ email, password })
//             });

//             const data = await response.json();

//             if (!response.ok) {
//                 throw new Error(data.message || 'Something went wrong');
//             }

//             // Handle successful login
//             console.log('Login successful:', data);
//             // Navigate to the home page
//             history.push('/home');

//         } catch (error) {
//             console.error('Error:', error);
//             setError(error.message);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     return (
//         <div className="login-container">
//             <h2>Login</h2>
//             {error && <div className="login-error-message">{error}</div>}
//             <form onSubmit={handleSubmit}>
//                 <div className="login-input-group">
//                     <label htmlFor="email">Email</label>
//                     <input
//                         type="email"
//                         id="email"
//                         name="email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <div className="login-input-group">
//                     <label htmlFor="password">Password</label>
//                     <input
//                         type="password"
//                         id="password"
//                         name="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <button type="submit" className="login-button" disabled={isLoading}>
//                     {isLoading ? 'Logging in...' : 'Login'}
//                 </button>
//             </form>
//             <div className="login-links">
//                 <a href="/reset">Forgot Password?</a>
//                 <a href="/register">Register</a>
//             </div>
//         </div>
//     );
// };

// export default Loginre;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import './loginre.css';

const Loginre = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await fetch('https://api.my-friend.co/login/', {
                method: 'POST',
                
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Something went wrong');
            }

            toast.success('Login successful!');
            // Pass the user ID to the profile page
            // console.log(data)
            // navigate('/', { state: { userId: data.user_id } });
            if (data.user_id === 'admin') {
                navigate('/admin', { state: { userId: data.user_id } });
            } else if (data.user_id === 'visa') {
                navigate('/visa', { state: { userId: data.user_id } });
            } else {
                navigate('/home', { state: { userId: data.user_id } });
            }

        } catch (error) {
            console.error('Error:', error);
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-page">
        <div className="login-contain">
            <ToastContainer />
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <div className="login-input-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        style={{ display: 'block', border: '1px solid black', padding: '10px' }} // Temporary inline styles
                    />
                </div>
                <div className="login-input-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        style={{ display: 'block', border: '1px solid black', padding: '10px' }} 
                    />
                </div>
                <button type="submit" className="login-button" disabled={isLoading}>
                    {isLoading ? 'Logging in...' : 'Login'}
                </button>
            </form>
            <div className="login-links">
                <a href="/reset">Forgot Password?</a>
                <a href="/register">Register</a>
            </div>
        </div>
        </div>
    );
};

export default Loginre;




// "start": "set NODE_OPTIONS=--openssl-legacy-provider && react-scripts start"
