import React, { useState } from 'react';

import youtubeImage from "../src/images/youtube.png";
import fbImage from "../src/images/facebook.png"
import twitterImage from "../src/images/twitter.png"
import mfcImage from "../src/images/mfc_logo.png"
import instagImage from "../src/images/ig.png"

import uni from "../src/images/uni.jpg";
import unione from "../src/images/unione.jpg";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import './Home.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import select from "../src/images/select.png";
import "./universities.css"
import acco from '../src/images/acco.jpg';

function Services() {
  // Define settings for the Slider component
  const settings = {
    dots: true,
    infinite: true,
    speed: 2300, // number increase for speed
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000, // Set the time in milliseconds for automatic sliding
  };

  // State to manage tab selection
  const [selectedTab, setSelectedTab] = useState('home');

  // Handle tab click event
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    // Add logic to handle tab change, such as updating content or navigating to a different page
  };

  // State to manage image visibility
  const [isVisible, setIsVisible] = useState(false);

  // Function to handle image load event
  const handleImageLoad = () => {
    setIsVisible(true);
  };

  // Style for image container opacity transition
  const containerStyle = {
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 6s ease-in-out',
  };

  return (
    <div className='nb'>
      <div className='full screen'>
        <div>
          <h2></h2>
          <Slider {...settings}>
            <div className="image-with-text-container">
              <img src={uni} alt="image" width="100%" height="710px" />
            </div>
            <div className="image-with-text-container">
              <img src={unione} alt="image" width="100%" height="100%" />
            </div>
          </Slider>
        </div>
      </div>

      <nav id="nav1">
        <div className='image'>
          <Link to="/"><img src={mfcImage} alt="image" width="150px" height="60px" /></Link>
        </div>
        <div>
          <ul className="list">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/latestoffers">Latest Offers</Link></li>
            <li><Link to="/legal_support">Legal Support</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div className='button'>
          <Link to="/login"><li>login</li></Link>
          <button className="btn"><b>+91-40-2939 7263</b></button>
        </div>
      </nav>
      <div className="head2">
        <div className='image2'></div>
      </div>
      {/* Content boxes */}
      {/* Your content boxes JSX */}
      <div className='body '>
        <div className='first'>
          <div className='i1'><img src={select} alt="sc" width="600px" height="500px" /></div>
          <div className='matter'>
            <ul>At myfriend.co, we understand the complexities of the visa application process for studying abroad. Our expert visa consultancy service is meticulously designed to guide students through each step with precision and ease. Whether it's navigating through the required documentation, understanding visa requirements, or preparing for interviews, our team of professionals is dedicated to alleviating any uncertainties along the way. With our support, students can confidently move forward in their international education journey, knowing that their visa application process is in capable hands.</ul></div>
            </div>
        </div>
        <div className='body '>
        <div className='first'>
        <div className='matter'>
            <ul>At myfriend.co, we understand the complexities of the visa application process for studying abroad. Our expert visa consultancy service is meticulously designed to guide students through each step with precision and ease. Whether it's navigating through the required documentation, understanding visa requirements, or preparing for interviews, our team of professionals is dedicated to alleviating any uncertainties along the way. With our support, students can confidently move forward in their international education journey, knowing that their visa application process is in capable hands.</ul></div>
          <div className='i1'><img src={acco} alt="sc" width="700px" height="500px" /></div>
         
            </div>
        </div>
        
        
       
       <nav id="nav2" >
        <div className='last'>
          <img src={mfcImage} alt="sc" width="150px" height="70px" />
          <p>On the other hand,we denounce </p>
          <p>with righteous indignation and </p>
          <p>dislike men who are so</p>
          <div className="f">
            <div className='i1'><img src={fbImage} alt="sc" width="55px" height="55px" /></div>
            <div className='i2'><img src={twitterImage} alt="sc" width="40px" height="40px" /></div>
            <div className='i3'><img src={instagImage} alt="sc" width="40px" height="40px" /></div>
            <div className='i4'><img src={youtubeImage} alt="sc" width="49px" height="40px" /></div>
            <div className='available'>
              <h4>Available POS</h4>
              <div className='food'>
                <p>Food Delivery</p>
                <p>Coffee Shop</p>
                <p>Clothing Store</p>
                <p>E-Commerce</p>
              </div>
            </div>
            <div className='company'>
              <h4>Company</h4>
              <div className='features'>
                <p>Features</p>
                <p>FAQ</p>
                <p>Privacy Policy</p>
                <p>Terms of Use</p>
              </div>
            </div>
            <div className='subscribe'>
              <h4>Subscribe Us</h4>
              <div className='get'>
                <p> Get Business news,tip and solutions to</p>
                <p> your problems from our experts.</p>
              </div>
              <input type='email' id="tbemail" placeholder='Enter your email'></input><br></br>
              <input type='button' id="btnsub" value="subscribe"></input>
            </div>
          </div>
          <div className='copy'><p>Copyright @ <b>HBIC Solutions</b> 2024</p></div>
        </div>
      </nav>
    </div>
  );
}

export default Services;
