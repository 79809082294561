// import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const School = () => {
//   const [course, setCourse] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [editableIndex, setEditableIndex] = useState(null);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await fetch("https://api.my-friend.co/college/1");
//       if (!response.ok) {
//         throw new Error("Failed to fetch data");
//       }
//       const data = await response.json();
//       const parsedData = JSON.parse(data);
//       const fieldsData = parsedData.map((row) => row.fields);
//       setCourse(fieldsData);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setError(error.message);                  
//       toast.error("Failed to fetch data");
//       setLoading(false);
//     }
//   };

//   const handleDelete = (index) => {
//     console.log("Delete row at index:", index);
//   };

//   const handleEdit = (index) => {
//     setEditableIndex(index);
//   };

//   const handleSave = (index) => {
//     console.log("Save row at index:", index);
//     setEditableIndex(null);
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   if (!course) {
//     return <div>No course data available.</div>;
//   }

//   const fieldNames = Object.keys(course[0]);

//   return (
//     <div className="excel-container">
//       {course.map((row, index) => (
//         <div className="table-row" key={index}>
//           <div className="table-cell">
//             {/* Make sure the image URL is correctly formatted */}
//             <img
//   src={`https://api.my-friend.co/media/schools&colleges/1/${row.image1}`}
//   alt="College"
//   onError={(e) => {
//     e.target.onerror = null; // Prevent infinite loop
//     e.target.src = "placeholder_image_url"; // Replace with a placeholder image URL
//   }}
// />
//           </div>
//           {fieldNames.map((fieldName) => (
//             <div className="table-cell" key={fieldName}>
//               {editableIndex === index ? (
//                 <input
//                   type="text"
//                   value={row[fieldName]}
//                   onChange={(e) => {
//                     // Implement handleChange for each input field
//                   }}
//                 />
//               ) : (
//                 row[fieldName]
//               )}
//             </div>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default School;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const Schools= () => {
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editableIndex, setEditableIndex] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://api.my-friend.co/college/1");
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const parsedData = JSON.parse(data);
      const fieldsData = parsedData.map((row) => {
        // Filter out latitude and longitude fields
        const filteredRow = { ...row.fields };
        delete filteredRow.latitude;
        delete filteredRow.longitude;
        delete filteredRow.status; // Remove 'status' field
        delete filteredRow.city; // Remove 'city' field
        return filteredRow;
      });
      setCourse(fieldsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
      toast.error("Failed to fetch data");
      setLoading(false);
    }
  };

  const handleDelete = (index) => {
    console.log("Delete row at index:", index);
  };

  const handleEdit = (index) => {
    setEditableIndex(index);
  };

  const handleSave = (index) => {
    console.log("Save row at index:", index);
    setEditableIndex(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!course) {
    return <div>No course data available.</div>;
  }

  const fieldNames = Object.keys(course[0]);

  return (
    <div className="excel-container">
      {course.map((row, index) => (
        <div className="table-row" key={index}>
          <div className="image-cell">
            <Link to="/school" className="link-container">
              <img
               src={`https://api.my-friend.co/media/schools&colleges/1/${row.Pk}`}
                alt="College Image"
              />
            </Link>
          </div>
          <div className="content-cell">
            {fieldNames.map((fieldName) => (
              fieldName !== 'image1' && fieldName !== 'image2' && fieldName !== 'status' && fieldName !== 'city' ? ( // Check if the field name is not an image or excluded field
                <div className="table-cell" key={fieldName}>
                  {fieldName === 'name' ? ( // Check if the field name is 'name'
                    <strong style={{ display: 'block', textAlign: 'center' }}>{row[fieldName]}</strong>
                  ) : (
                    fieldName === 'address' ? ( // Check if the field name is 'address'
                      <div>
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="map-marker-icon"/> {row[fieldName]}
                      </div>
                    ) : (
                      editableIndex === index ? (
                        <input
                          type="text"
                          value={row[fieldName]}
                          onChange={(e) => {}}
                        />
                      ) : (
                        row[fieldName]
                      )
                    )
                  )}
                </div>
              ) : null
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Schools;
