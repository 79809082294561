import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './otp.css';
import { useNavigate } from 'react-router-dom';
const OtpVerification = () => {
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    
    const registeredEmail = localStorage.getItem('registeredEmail');
    if (registeredEmail) {
      setEmail(registeredEmail);
    }
  }, []);

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://api.my-friend.co/check-otp/${email}/`, { otp });
      console.log('OTP verified:', response.data);
      setMessage('OTP verified successfully!');
      navigate('/loginre');
      // Handle success (e.g., show a success message or redirect)
    } catch (error) {
      console.error('There was an error verifying OTP:', error);
      setMessage('Error verifying OTP. Please try again.');
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <div className="contain">
      <h2>OTP Verification</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
         <input type="email" value={email} readOnly />
        </div>
        <div>
          <label>OTP:</label>
          <input type="text" value={otp} onChange={handleChange} required
           style={{ display: 'block', border: '1px solid black', padding: '10px' ,width: '100%' }}/>
          
        </div>
        <button type="submi">Verify OTP</button>
      </form>
      {message && <p className="fadeInOu">{message}</p>}
    </div>
  );
};

export default OtpVerification;
