import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const TicketBooking = () => {
  const [personalDetails, setPersonalDetails] = useState("");
  const [ticketClass, setTicketClass] = useState("");
  const [countryToVisit, setCountryToVisit] = useState("");
  const [journeyDate, setJourneyDate] = useState("");
  const [departure, setDeparture] = useState("");
  const [arrivalLocation, setArrivalLocation] = useState("");
  const [otherDetails, setOtherDetails] = useState("");

  const navigate = useNavigate();

  const upload = async () => {
    try {
      const requestData = {
        personal_details: personalDetails,
        ticket_class: ticketClass,
        country_to_visit: countryToVisit,
        journey_date: journeyDate,
        departure: departure,
        arrival_location: arrivalLocation,
        other_details: otherDetails
      };

      const response = await fetch("https://api.my-friend.co/tbs_form/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      });

      if (response.ok) {
        toast("Data uploaded successfully!");
        navigate("/Home");
      } else {
        throw new Error("Failed to upload data");
      }
    } catch (error) {
      console.error("Error uploading data:", error);
      toast.error("Failed to upload data");
    }
  };

  return (
    <div>
      <div className="ticket-booking-form-container">
        <h2>Ticket Booking Support</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="ticket-booking-form-group">
            <label>Personal Details</label>
            <input
              type="text"
              value={personalDetails}
              onChange={(e) => setPersonalDetails(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px',width:'400px' }}
              className="ticket-booking-input"
            />
          </div>

          <div className="ticket-booking-form-group">
            <label>Ticket Class</label>
            <input
              type="text"
              value={ticketClass}
              onChange={(e) => setTicketClass(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
              className="ticket-booking-input"
            />
          </div>

          <div className="ticket-booking-form-group">
            <label>Country to Visit</label>
            <input
              type="text"
              value={countryToVisit}
              onChange={(e) => setCountryToVisit(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
              className="ticket-booking-input"
            />
          </div>

          <div className="ticket-booking-form-group">
            <label>Journey Date</label>
            <input
              type="date"
              value={journeyDate}
              onChange={(e) => setJourneyDate(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
              className="ticket-booking-input"
            />
          </div>

          <div className="ticket-booking-form-group">
            <label>Departure</label>
            <input
              type="text"
              value={departure}
              onChange={(e) => setDeparture(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
              className="ticket-booking-input"
            />
          </div>

          <div className="ticket-booking-form-group">
            <label>Arrival Location</label>
            <input
              type="text"
              value={arrivalLocation}
              onChange={(e) => setArrivalLocation(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
              className="ticket-booking-input"
            />
          </div>

          <div className="ticket-booking-form-group">
            <label>Other Details</label>
            <textarea
              value={otherDetails}
              onChange={(e) => setOtherDetails(e.target.value)}
              required
              style={{ display: 'block', border: '1px solid black', padding: '10px' }}
              className="ticket-booking-textarea"
            ></textarea>
          </div>
         
   <button type="submit" className="ticket-booking-button">submit
   </button>

          <p className="ticket-booking-note">
            Enter correct details{" "}
            <span>
              <b>Home page</b>
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

export default TicketBooking;
