import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';



const Loginre = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
      
        technical_writing_skills: '',
        research_topic_selection: '',
        write_proposal: '',
        dissertation_guidance: '',
        project_planning: '',
        resource_management: '',
        plagiarism_impacts: '',
        bibliography: ''
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await fetch('https://api.my-friend.co/login/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Something went wrong');
            }

            toast.success('Login successful!');
            // Pass the user ID to the profile page
            console.log(data);
            navigate('/profile', { state: { userId: data.user_id } });

        } catch (error) {
            console.error('Error:', error);
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-page">
            <div className="login-contain">
                <ToastContainer />
                <h2>Academic Corner</h2>
                <form onSubmit={handleSubmit}>
                   
                    {/* Additional fields */}
                    <div className="login-input-group">
                        <label htmlFor="technical_writing_skills">Technical Writing Skills</label>
                        <input
                            type="text"
                            name="technical_writing_skills"
                            value={formData.technical_writing_skills}
                            onChange={handleChange}
                            style={{ display: 'block', border: '1px solid black', padding: '10px' }}
                        />
                    </div>
                    <div className="login-input-group">
                        <label htmlFor="research_topic_selection">Research Topic Selection</label>
                        <input
                            type="text"
                            name="research_topic_selection"
                            value={formData.research_topic_selection}
                            onChange={handleChange}
                            style={{ display: 'block', border: '1px solid black', padding: '10px' }}
                        />
                    </div>
                    <div className="login-input-group">
                        <label htmlFor="write_proposal">Write Proposal</label>
                        <input
                            type="text"
                            name="write_proposal"
                            value={formData.write_proposal}
                            onChange={handleChange}
                            style={{ display: 'block', border: '1px solid black', padding: '10px' }}
                        />
                    </div>
                    <div className="login-input-group">
                        <label htmlFor="dissertation_guidance">Dissertation Guidance</label>
                        <input
                            type="text"
                            name="dissertation_guidance"
                            value={formData.dissertation_guidance}
                            onChange={handleChange}
                            style={{ display: 'block', border: '1px solid black', padding: '10px' }}
                        />
                    </div>
                    <div className="login-input-group">
                        <label htmlFor="project_planning">Project Planning</label>
                        <input
                            type="text"
                            name="project_planning"
                            value={formData.project_planning}
                            onChange={handleChange}
                            style={{ display: 'block', border: '1px solid black', padding: '10px' }}
                        />
                    </div>
                    <div className="login-input-group">
                        <label htmlFor="resource_management">Resource Management</label>
                        <input
                            type="text"
                            name="resource_management"
                            value={formData.resource_management}
                            onChange={handleChange}
                            style={{ display: 'block', border: '1px solid black', padding: '10px' }}
                        />
                    </div>
                    <div className="login-input-group">
                        <label htmlFor="plagiarism_impacts">Plagiarism Impacts</label>
                        <input
                            type="text"
                            name="plagiarism_impacts"
                            value={formData.plagiarism_impacts}
                            onChange={handleChange}
                            style={{ display: 'block', border: '1px solid black', padding: '10px' }}
                        />
                    </div>
                    <div className="login-input-group">
                        <label htmlFor="bibliography">Bibliography</label>
                        <input
                            type="text"
                            name="bibliography"
                            value={formData.bibliography}
                            onChange={handleChange}
                            style={{ display: 'block', border: '1px solid black', padding: '10px' }}
                        />
                    </div>
                    <button type="submit" className="login-button" disabled={isLoading}>
                        {isLoading ? 'Logging in...' : 'Submit'}
                    </button>
                </form>
                <div className="login-links">
                   
                </div>
            </div>
        </div>
    );
};

export default Loginre;
