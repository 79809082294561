// import React from 'react'

import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./Home.css";
import { useLocation } from "react-router-dom";

import Swiper from "swiper";
import universities from "../src/images/universities.png";
import group1 from "../src/images/group1.png";
import group2 from "../src/images/group2.png";
import group3 from "../src/images/group3.png";
import group22 from "../src/images/group22.png";
import buttomnavbar from "../src/images/buttomnavbar.png";
import localguide from "../src/images/localguidee.png";
import group9 from "../src/images/group9.png"
import uni from "../src/images/uni.png";
import deco from "../src/images/daco.png";
import news from "../src/images/news.jpg";
import ukcon from "../src/images/ukcon.png";
import pport from "../src/images/pport.jpg";
import guid from "../src/images/guid.png";
import samko from "../src/images/samko.jpg";
import accom from "../src/images/accom.jpg";
import ist from "../src/images/ist.png";
import face from "../src/images/face.png";
import dart from "../src/images/dart.jpg";
import healt from "../src/images/healt.jpg";
import mapp from "../src/images/mapp.jpg";
import card from "../src/images/card.jpg";
import academic from "../src/images/academic.png";
import news1 from "../src/images/news2.jpg";
import twe from "../src/images/twe.png";
import news3 from "../src/images/news1.jpg";
import driver from "../src/images/driver.png";

import onboard from "../src/images/onboarded.png";
import support from "../src/images/support.png";
import ticketbooking from "../src/images/ticket_booking.png";
import air_dest from "../src/images/airport_destination.png";
import schools from "../src/images/school.png";
import leisurecenter from "../src/images/leisure_centers.png";
import academiccorner from "../src/images/acedemiccorner.png";
import shoppingcorner from "../src/images/shopping_corner.png";
import travelcompanion from "../src/images/travel_companion.png";
import bookdriver from "../src/images/car.png";
import youtube from "../src/images/youtube.png";
import managers from "../src/images/manage3.jpg";
import banner from "../src/images/banner.png";
import fb from "../src/images/facebook.png";
import twitte from "../src/images/twitter.png";
import mfc from "../src/images/mfc_logo.png";
import destini from "../src/images/destini.png";
import legal from "../src/images/legal.png";
import manager from "../src/images/manage2.jpg";
import collegee from "../src/images/collegee.png";
import pre from "../src/images/pre1.jpg";
import manageo from "../src/images/manage1.jpg";
import doc from "../src/images/doc.png";
import pree from "../src/images/pre2.jpg";
import instag from "../src/images/ig.png";
import land from "../src/images/land.jpg";
import coro from "../src/images/coro.png";
import sec from "../src/images/sec.jpg";
// import acedemiccorner from  "../src/image/acedemiccorner.png";
import airport from "../src/images/airport.png";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import car from "../src/images/car.png";


import shoping from "../src/images/Group10.png";
import visa from "../src/images/visa.png";
import peri from "../src/images/peri.png";
import previ from "../src/images/previ.png";
import jobs from "../src/images/jobs.png";
import basket from "../src/images/basket.png";
import centers from "../src/images/centers.png";
import booking from "../src/images/booking.png";
import travel from "../src/images/travel.png";
import pic1 from "../src/images/pic1.png";
import pic2 from "../src/images/pic2.png";
import da from "../src/images/da.png";
import pex from "../src/images/pex.jpg";
import pic3 from "../src/images/pic3.png";
import acomidation from "../src/images/acomidation.png";
import school from "../src/images/school.png";
import dac from "../src/images/dac.png";
import services from "./Services";
import ukt from "../src/images/ukt.png";

import tr from "../src/images/tr.png";
import jobb from "../src/images/jobb.png"
import italy from "../src/images/italy.png";
import carr from "../src/images/carr.png";
import phone from "../src/images/icon-phone.png";
import mixed from "../src/images/mixed.png";
import years from "../src/images/years.png";
import cont1 from "../src/images/count1.jpg";
import flaga from "../src/images/flaga.png";
import grouppp from "../src/images/grouppp.png"
import form from "../src/images/from.png";
import vis from "../src/images/vis.png";
import col from "../src/images/col.png";
import canada from "../src/images/canadaa.png";
import germany from "../src/images/germany.png";
import liner from "../src/images/liner.png";
import profile from "../src/images/profilepic.png";
import { SiSemanticscholar } from 'react-icons/si';
import { BiUserVoice } from 'react-icons/bi';
import { RiHandCoinLine } from 'react-icons/ri';
import { LiaUniversitySolid, LiaHandsHelpingSolid } from 'react-icons/lia';
import { MdOutlineAssignment } from 'react-icons/md';
import { TiTickOutline } from 'react-icons/ti';
import { TbProgressCheck } from 'react-icons/tb';
import { AiOutlineSolution } from 'react-icons/ai';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 //3lines
//npm install slick-carousel
//npm install react-slick
// 2 lines in termina install

function Home() {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  console.log(location.state)
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
 
  //here
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 2300, // number increase for speed
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000, // Set the time in milliseconds for automatic sliding
  };
  
  // here for speed of the cauroser
  // here
  const [selectedTab, setSelectedTab] = useState("home");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    // Add logic to handle tab change, such as updating content or navigating to a different page
  };
  
  const [isVisible, setIsVisible] = useState(false);

  const handleImageLoad = () => {
    setIsVisible(true);
  };

  const containerStyle = {
    opacity: isVisible ? 1 : 0,
    transition: "opacity 6s ease-in-out",
  };
  const containerStyle2 = {
    opacity: isVisible ? 1 : 0,
    transition: "opacity 6s ease-in-out",
  };
  //here opicity
const toggleDropdown = () => {
  // Define the behavior of toggleDropdown function here
};


const [isOpen, setIsOpen] = useState(false); 
function Home() {
  return (
    <div>
      <h1>Home Component</h1>
    </div>
  );
}
const slideRef = useRef(null);
const [loadingProgress, setLoadingProgress] = useState(0);

const handleClickNext = () => {
  let items = slideRef.current.querySelectorAll(".item");
  slideRef.current.appendChild(items[0]);
};

const handleClickPrev = () => {
  let items = slideRef.current.querySelectorAll(".item");
  slideRef.current.prepend(items[items.length - 1]);
};

const data = [
  {
    id: 1,
    imgUrl: "https://images.pexels.com/photos/6476186/pexels-photo-6476186.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    desc: "Some beautiful roads cannot be discovered without getting loss.",
    name: "EXPLORE NATURE",
  },
  {
    id: 2,
    imgUrl:
      "https://images.pexels.com/photos/301920/pexels-photo-301920.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    desc: "Some beautiful roads cannot be discovered without getting loss.",
    name: "EXPLORE NATURE",
  },
  {
    id: 3,
    imgUrl:
      "https://images.pexels.com/photos/267885/pexels-photo-267885.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    desc: "Some beautiful roads cannot be discovered without getting loss.",
    name: "EXPLORE NATURE",
  },
  {
    id: 5,
    imgUrl: "https://images.pexels.com/photos/9159651/pexels-photo-9159651.jpeg",
    desc: "Some beautiful roads cannot be discovered without getting loss.",
    name: "EXPLORE NATURE",
  },
  {
    id: 6,
    imgUrl:
      "https://images.pexels.com/photos/8199165/pexels-photo-8199165.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    desc: "Some beautiful roads cannot be discovered without getting loss.",
    name: "EXPLORE NATURE",
  },
];
const App = () => {
  useEffect(() => {
    const swiper = new Swiper(".blog-slider", {
      spaceBetween: 30,
      effect: "fade",
      loop: true,
      mousewheel: {
        invert: false,
      },
      pagination: {
        el: ".blog-slider__pagination",
        clickable: true,
      },
    });

    return () => {
      swiper.destroy();
    };
  }, []);

}
const [selectedCard, setSelectedCard] = useState('c1');

  const handleChange = (event) => {
    setSelectedCard(event.target.id);
  };
  return (
  <div class="content">
        <nav id="nav1">
    
   
</nav> 
{/* <nav id="nav1">
  <div className="nav-container">
    <div className="nav-header">
      <Link to="/">
        <img src={mfc} alt="image" width="130px" height="55px" />
      </Link>
     
      
    </div>
    <div className="menu-toggle" onClick={toggleMenu}>
      <div className="hamburger"></div>
      <div className="hamburger"></div>
      <div className="hamburger"></div>
    </div>
  </div>
  <div id="menu-options" className={showMenu ? "show-menu" : ""}>
    <div className="menu-container">
      <ul>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
  <a href="#about-section">About</a>
</li>
<li>
  <a href="#services-section">Services</a>
</li>
        <li>
          <Link to="/privacy"> Privacy</Link>
        </li>
        <li>
          <Link to="/legalsupport">Legal Support</Link>
        </li>
        <li>
          <Link to="/profile">Contact</Link>
        </li>
        <li>
          <Link to="/loginre">login</Link>
        </li>
        <li>
          <Link>
            <button className="animated-button"><b>+91-40-2939 7263</b></button>
          </Link>
         
          <Link 
      to={{
        pathname: "/profile",
        state: location.state
      }} 
      className="profile-link"
    >
      <img src={profile} alt="Profile" height="30px" width="30px"/>
    </Link>
        </li>
       
      </ul>
    </div>
  </div>
</nav>
       */}
        <nav id="nav1">
       
      <div className="nav-container">
        <div className="nav-header">
          <Link to="/">
            <img src={mfc} alt="Logo" width="130px" height="55px" />
          </Link>
        </div>
        <div className="menu-toggle" onClick={toggleMenu}>
          <div className={`hamburger ${showMenu ? 'rotate' : ''}`}></div>
          <div className={`hamburger ${showMenu ? 'fade' : ''}`}></div>
          <div className={`hamburger ${showMenu ? 'rotate' : ''}`}></div>
        </div>
      </div>
      <div id="menu-options" className={showMenu ? 'show-menu' : ''}>
        <div className="menu-container">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><a href="#about-section">About</a></li>
            <li><a href="#services-section">Services</a></li>
            <li><Link to="/privacy">Privacy</Link></li>
            <li><Link to="/legalsupport">Legal Support</Link></li>
            <li><Link to="/profile">Contact</Link></li>
            <li><Link to="/loginre">Login</Link></li>
            <li>
              <button className="animated-button"><b>+91-40-2939 7263</b></button>
              <Link to="/profile" className="profile-link">
                <img src={profile} alt="Profile" height="30px" width="30px" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
      <div class="slider">
      <Slider {...settings}>
     <div className="image-with-text-container">
       <img src={land} alt="image" width="100%" height="710px" />
       <div className="slidecont" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
         <h2>Explore Visa Assistance and Job <br></br>Opportunities Abroad!</h2>
       </div>
     </div>
     <div className="image-with-text-container">
       <img src={coro} alt="image" width="100%" height="100%" />
       <div className="slidecont" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
         <h2>Enhance your study abroad journey<br></br> with our app's diverse programs. </h2>
       </div>
     </div>
   </Slider>
  
      </div>
        <div class='visaadd'>
    <div className="head2">
    <div className="image2"></div>
    </div>
    <div className="three">
      <div className="pic1">
      <img src={pic1} alt="sc2" width="380px" height="260px" />
      <div className="overlay">
        <div className="pi">
          <img src={tr} alt="sc2" width="75px" height="75px" />
          <h2>Visa Opportunities and Job Openings</h2>
        </div>
        <div>
          <p>"Unlock your international career potential with our student-focused app!"</p>
        </div>
        <div className="button-border">
          <button className="read-more-button">Read More</button>
        </div>
      </div>
      </div>

     <div className="pic2">
      <img src={pic2} alt="sc2" width="380px" height="260px" />
      <div className="overlay">
        <div className="pi">
          <img src={tr} alt="sc2" width="75px" height="75px" />
          <h2>Immigration Programs for Your Future Abroad</h2>
        </div>
        <div>
          <p>"Navigate your international study journey seamlessly with our dedicated app."</p>
        </div>
        <div className="button-border">
          <button className="read-more-button">Read More</button>
        </div>
      </div>
      </div>
      <div className="pic3">
      <img src={pic3} alt="sc2" width="380px" height="260px" />
      <div className="overlay">
        <div className="pi">
          <img src={tr} alt="sc2" width="75px" height="75px" />
          <h2>Essential Programs for Your Abroad Journey</h2>
        </div>
        <div>
          <p>"Discover tailored language, cultural, and academic programs for your international journey with our app. Start exploring now!"</p>
        </div>
        <div className="button-border">
          <button className="read-more-button">Read More</button>
        </div>
      </div>
    </div>
   </div>
   <div id="services-section" className="teamnumbers11">
  <p>Services</p>
  <h2>
    Customized Solutions for Your Every Need.<br/>
    Experience Excellence with Our Range of <span className="highlighted">Services</span>
  </h2>
</div>

<div className="alli">
  <div>
    <div className="innercontainer2">
      <div>
        <Link to="/universities">
          <img src={uni} alt="sc2" />
          <p>Universities</p>
        </Link>
      </div>
    </div>
  </div>
  <div>
    <div className="innercontainer2">
      <div>
        <Link to="/accommodation">
          <img src={accom} alt="sc2" />
          <p>Accommodation</p>
        </Link>
      </div>
    </div>
  </div>
  <div>
    <div className="innercontainer2">
      <div>
        <Link to="/jobs">
          <img src={jobb} alt="sc2" />
          <p>Job (Full Time & Part Time)</p>
        </Link>
      </div>
    </div>
  </div>
  <div>
    <div className="innercontainer2">
      <div>
        <Link to="/visa">
          <img src={vis} alt="sc2" />
          <p>Visa Consultancy</p>
        </Link>
      </div>
    </div>
  </div>
  <div>
    <div className="innercontainer2">
      <div>
        <Link to="/airport">
          <img src={destini} alt="sc2" />
          <p>Airport to Destination Support</p>
        </Link>
      </div>
    </div>
  </div>
</div>

<div className="secall">
  <div className="innercontainer2">
    <div className="collegee">
      <Link to="/school">
        <img src={col} alt="sc2" />
        <p>Colleges</p>
      </Link>
    </div>
  </div>
  <div className="innercontainer2">
    <div className="legal">
      <Link to="/legalsupport">
        <img src={guid} alt="sc2" />
        <p>Legal Support</p>
      </Link>
    </div>
  </div>
  <div className="innercontainer2">
    <div>
      <Link to="/ticketbooking">
        <img src={deco} alt="sc2" />
        <p>Ticket Booking Support</p>
      </Link>
    </div>
  </div>
  <div className="innercontainer2">
    <div>
      <Link to="/localshopping">
        <img src={basket} alt="sc2" />
        <p>Travelling</p>
      </Link>
    </div>
  </div>
  <div className="innercontainer2">
    <div>
      <Link to="/travelling">
        <img src={carr} alt="sc2" />
        <p>Travel Companion</p>
      </Link>
    </div>
  </div>
</div>

<div className="thirdcon">
  <div className="innercontainer2">
    <div>
      <img src={grouppp} alt="sc2" />
      <p>Schools</p>
    </div>
  </div>
  <div className="innercontainer2">
    <div>
    <Link to="/academiccorner">
      <img src={academic} alt="sc2" />
      <p>Academic Corner</p>
      </Link>
    </div>
  </div>
  <div className="innercontainer2">
    <div>
      <Link to="/onboard">
      <img src={destini} alt="sc2" />
      <p>Onboard Support</p>
      </Link>
    </div>
  </div>
  <div className="innercontainer2">
    <div>
    <Link to="/onboard">
      <img src={liner} alt="sc2" />
      <p>Jobs at MFC</p>
    </Link>
    </div>
  </div>
  <div className="innercontainer2">
    <div>
      <img src={driver} alt="sc2" />
      <p>Book a Driver</p>
    </div>
  </div>
</div>
</div>

<div id="about-section" class="aboutthecompany"><h3>ABOUT THE COMPANY</h3></div>

<div class='about-container'>
  <div class="content-wrapper">
    <img src={mixed} alt="Company Image" class="company-image" />
    <div class="company-info">
  
      <h1>The market leading visa & immigration firm</h1>
      <p>
        HBIC Solutions offers innovative technology solutions tailored to diverse industries. With expertise in software development and digital transformation, we empower businesses to thrive in dynamic landscapes. Our customer-centric approach ensures personalized attention and tangible results for each client.
      </p>
      <div class="additional-info">
        <img src={years} alt="Years of Experience" class="experience-image" />
        <div class="highlights">
          <p>Offer 100% Genuine Assistance</p>
          <p>It’s Faster & Reliable Execution</p>
          <p>Accurate & Expert Advice</p>
          <div class="contact-info">
            <img src={phone} alt="Phone Icon" class="phone-icon" />
            <div>
              <p>7997000128</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="about-container1">
  <div class="content-wrapper1">
  <div class="image-container">
  <div class="overlay-text">
    <h3>WHAT DO WE OFFER</h3>
    <h1>Outstanding immigration</h1>
    <h1>visa services.</h1>
  </div>
  <div class="main-container1">
    <div class="container1">
      <img src={cont1} alt="sc2" />
      <h3>Student Visa</h3>
      <p>There are many variations of passages of available.</p>
    </div>
    <div class="container1">
      <img src={pre} alt="sc2" />
      <h3>Student Visa</h3>
      <p>There are many variations of passages of available.</p>
    </div>
    <div class="container1">
      <img src={pree} alt="sc2" />
      <h3>Student Visa</h3>
      <p>There are many variations of passages of available.</p>
    </div>
  </div>
  <div className="mixed-container">
            <div className="inner">
              <h6>COUNTRIES YOU CAN VISIT</h6>
              <h1>Countries we support for immigration</h1>
              <p>
              HBIC Solutions provides expert immigration support for students seeking global opportunities. Leveraging our network and legal know-how, we ensure smooth navigation of immigration processes. Whether for study, work, or residency, trust us to guide you through every step.
              </p>
              <button className="exp">Read More</button>
            </div>
            <div className="innercontainer">
              <div className="innercontainer1">
                <div>
                  <img src={ukt} alt="sc2" width="50%" height="50%" />
                  <p>United Kingdom</p>{" "}
                </div>
              </div>
              <div className="innercontainer1">
                <div>
                  <img src={canada} alt="sc2" width="50%" height="50%" />
                  <p>Canada</p>{" "}
                </div>
              </div>
            </div>
            <div className="innercontainer">
              <div className="innercontainer1">
                <div>
                  <img src={ukcon} alt="sc2" width="50%" height="50%" />
                  <p>United States</p>{" "}
                </div>
              </div>
              <div className="innercontainer1">
                <div>
                  <img src={italy} alt="sc2" width="50%" height="50%" />
                  <p>Italy</p>{" "}
                </div>
              </div>
            </div>
            <div className="innercontainer">
              <div className="innercontainer1">
                <div>
                  <img src={germany} alt="sc2" width="50%" height="50%" />
                  <p>Germany</p>{" "}
                </div>
              </div>
              <div className="innercontainer1">
                <div>
                  <img src={flaga} alt="sc2" width="50%" height="50%" />
                  <p>Australia</p>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
</div>
<div class="conten10">
  <div class="image.in">
    <div class="team-numbers">
      <div class="easy"><h2>
        Get your visa approved in<br/>3 easy simple
        <span class="highlighted">steps</span>
      </h2></div>
      
    </div>
    <div class="all-container">
      
         <div class="step-container">
        <div class="main-cont">
          <div class="cont">
            <img src={form} alt="" width="70px" height="80px" />
          </div>
        </div>
        <div class="cont-one">
          <div class="one">01</div>
        </div>
        <div class="cont-1">
          <h3>Complete online form</h3>
          <p>
            Sure, could you please provide the content you'd like me to use to
            complete the online form?
          </p>
        </div>
        </div>
       
        <div class="step-container">
        <div class="main-cont">
          <div class="cont">
            <img src={form} alt="" width="70px" height="80px" />
          </div>
        </div>
        <div class="cont-one">
          <div class="one">02</div>
        </div>
        <div class="cont-1">
          <h3>Documents & payment</h3>
          <p>
            Sure, could you please provide the content you'd like me to use to
            complete the online form?
          </p>
        </div>
        </div>
       <div class="step-container">
        <div class="main-cont">
          <div class="cont">
            <img src={form} alt="" width="70px" height="80px" />
          </div>
        </div>
        <div class="cont-one">
          <div class="one">03</div>
        </div>
        <div class="cont-1">
          <h3>Receive Your Visa</h3>
          <p>
            Sure, could you please provide the content you'd like me to use to
            complete the online form?
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="teamnumbers">
        <p>OUR TEAM MEMBERS</p>
        <h2>
          Meet our qualified visa &<br></br>
          immigration <span className="highlighted">experts</span>
        </h2>
      </div>
 <div>
      <div className="image">
        <div className="containe">
          <img src={manageo} alt="" width="220px" height="300px" />
          <p>MANAGER</p>
          <label className="changeColor">Jessica Brown</label>
        </div>
        <div className="containe">
          <img src={pex} alt="" width="220px" height="300px" />
          <p>MANAGER</p>
          <label className="changeColor">Mike Hardson</label>
        </div>
        <div className="containe">
          <img src={manager} alt="" width="220px" height="300px" />
          <p>MANAGER</p>
          <label className="changeColor">Aleesha Rose</label>
        </div>
        <div className="containe">
          <img src={managers} alt="" width="220px" height="300px" />
          <p>MANAGER</p>
          <label className="changeColor">Jessica Brown</label>
        </div>
      </div>
      </div>
      <div className="banner">
        <img src={banner} alt="sc2" width="100%" height="100%" />
        
      </div>
     {/* <div className="teamnumbers">  */}
        {/* <p>NEWS & UPDATES</p>
        <h2>
          Latest news directly<br></br>
          from the <span className="highlighted">blog</span>mm
        </h2>
      </div>
      <div className="image-con2">
        <img src={news} alt="sc2" width="23%" height="50%" />
        <div className="over">
          <h2>
            Top 9 most demand jobs<br></br>
            in <span className="highlighted">canada</span>
          </h2>
          <p>
            There are not many of passages of lorem ipsum available alteration
            in some form.
          </p>
        </div>
      </div>
      <div className="image-con">
        <img src={news1} alt="sc2" width="23%" height="50%" />
        <div className="over">
          <h2>
            Top 9 most demand jobs<br></br>
            in <span className="highlighted">canada</span>
          </h2>
          <p>
            There are not many of passages of lorem ipsum available alteration
            in some form.
          </p>
        </div>
      </div>
      <div className="image-con3">
        <img src={news3} alt="sc3" width="23%" height="50%" />
        <div className="over">
          <h2>
            Top 9 most demand jobs<br></br>
            in <span className="highlighted">canada</span>
          </h2>
          <p>
            There are not many of passages of lorem ipsum available alteration
            in some form.
          </p>
        </div>
      </div> */}
      <div className="teamnumbers">
      
        <p>Navigating Global Opportunities</p>
        <h2>
        Your Trusted Country Abroad <br></br>
         <span className="highlighted">Consultancy</span>
        </h2>
      </div>
      <div></div>
      <div className="wrapper">
      <div className="container">
        <input
          type="radio"
          name="slide"
          id="c1"
          checked={selectedCard === 'c1'}
          onChange={handleChange}
        />
        <label htmlFor="c1" className="card">
          <div className="row">
            <div className="icon">1</div>
            <div className="description">
              <h4>London, England, United Kingdom</h4>
              <p>Highly anticipated open-world game, next installment in series.</p>
            </div>
          </div>
        </label>

        <input
          type="radio"
          name="slide"
          id="c2"
          checked={selectedCard === 'c2'}
          onChange={handleChange}
        />
        <label htmlFor="c2" className="card">
          <div className="row">
            <div className="icon">2</div>
            <div className="description">
              <h4>PUBG BATTLEGROUND</h4>
              <p>Battle royale game, popular for intense multiplayer action.</p>
            </div>
          </div>
        </label>

        <input
          type="radio"
          name="slide"
          id="c3"
          checked={selectedCard === 'c3'}
          onChange={handleChange}
        />
        <label htmlFor="c3" className="card">
          <div className="row">
            <div className="icon">3</div>
            <div className="description">
              <h4>CALL OF DUTY</h4>
              <p>First-person shooter series known for intense multiplayer battles.</p>
            </div>
          </div>
        </label>

        <input
          type="radio"
          name="slide"
          id="c4"
          checked={selectedCard === 'c4'}
          onChange={handleChange}
        />
        <label htmlFor="c4" className="card">
          <div className="row">
            <div className="icon">4</div>
            <div className="description">
              <h4>VALORANT</h4>
              <p>Tactical shooter game with unique characters and abilities.</p>
            </div>
          </div>
        </label>
      </div>
    </div>
      <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
                        {/* <span className="block mb-2 text-lg font-semibold text-primary">
                                Our Services
                            </span> */}
                       
                    </div>
                    <div className="teamnumbers">
        
        <h2>
        Why Us?<br></br>
        9 Main Reasons to Choose <span className="highlighted">Headstart</span>
        </h2>
      </div>
      <div className="grid-container">
  <div className="grid-item">
    <div className="item-content">
      <div className="icon-container">
        <TiTickOutline className='icon' />
      </div>
      <h6 className="item-title">Expertise</h6>
      <p className="item-description">
        Our team consists of experienced and qualified professionals with in-depth knowledge of the education systems and admission processes of various countries.
      </p>
    </div>
  </div>
  <div className="grid-item">
    <div className="item-content">
      <div className="icon-container">
        <AiOutlineSolution className='icon' />
      </div>
      <h6 className="item-title">Customized Solutions</h6>
      <p className="item-description">
        We understand that each student is unique, and we provide personalized solutions based on their individual needs and requirements.
      </p>
    </div>
  </div>
  <div className="grid-item">
    <div className="item-content">
      <div className="icon-container">
        <TbProgressCheck className='icon' />
      </div>
      <h6 className="item-title">Transparency</h6>
      <p className="item-description">
        We maintain complete transparency throughout the process, and our students are regularly updated about the progress of their application.
      </p>
    </div>
  </div>
</div>
</div>
<div className="grid-container">
  <div className="grid-item">
    <div className="item-content">
      <div className="icon-container">
        <RiHandCoinLine  className='icon' />
      </div>
      <h6 className="item-title">Affordable Services</h6>
      <p className="item-description">
      We believe that everyone deserves quality education and we offer the lowest fee packages.
      </p>
    </div>
  </div>
  <div className="grid-item">
    <div className="item-content">
      <div className="icon-container">
        <LiaUniversitySolid className='icon' />
      </div>
      <h6 className="item-title">University Selection</h6>
      <p className="item-description">
      We help students select the right course, college or university, and country based on their academic profile, interests, and budget.
      </p>
    </div>
  </div>
  <div className="grid-item">
    <div className="item-content">
      <div className="icon-container">
        <LiaHandsHelpingSolid className='icon' />
      </div>
      <h6 className="item-title">Assistance Guidance</h6>
      <p className="item-description">
      We assist students in the entire application process, including filling out application forms, writing SOPs, essays, and letters of recommendation etc.
      </p>
    </div>
  </div>
</div>
<div className="grid-container">
  <div className="grid-item">
    <div className="item-content">
      <div className="icon-container">
        <MdOutlineAssignment className='icon' />
      </div>
      <h6 className="item-title">Visa Assistance</h6>
      <p className="item-description">
      We guide students through the visa process, including preparing the required documents, scheduling visa workshops, and providing guidance on visa interview preparation.
      </p>
    </div>
  </div>
  <div className="grid-item">
    <div className="item-content">
      <div className="icon-container">
        <BiUserVoice className='icon' />
      </div>
      <h6 className="item-title">Pre-Departure Briefing</h6>
      <p className="item-description">
      We provide students with a comprehensive briefing on what to expect once they reach their destination country, including information on accommodation, travel, and orientation.
      </p>
    </div>
  </div>
  <div className="grid-item">
    <div className="item-content">
      <div className="icon-container">
        <SiSemanticscholar className='icon' />
      </div>
      <h6 className="item-title">Scholarship Assistance</h6>
      <p className="item-description">
      We provide information on available scholarships and assist students in applying.    </p>
    </div>
  </div>
</div>
<div className="gap"></div>

 {/* <div class="blog-slider">
      <div class="blog-slider__wrp swiper-wrapper">
      
        {data.map((data) => (
          <div key={data.id} class="blog-slider__item swiper-slide">
            <div class="blog-slider__img">
              <img src={data.photo} alt="" />
            </div>
            <div class="blog-slider__content">
              <span class="blog-slider__code">{data.location}</span>
              <div class="blog-slider__title">{data.title}</div>
              <div class="blog-slider__text">{data.desc}</div>
              <div class="blog-slider__code">
                <p> ₹ {data.price}</p>
                <p> {data.size}</p>
              </div>
              <button class="blog-slider__button">BOOK NOW</button>
            </div>
          </div>
        ))}

       
      </div>
    </div>

<div class="blog-slider">
      <div class="blog-slider__wrp swiper-wrapper">
      
        {data.map((data) => (
          <div key={data.id} class="blog-slider__item swiper-slide">
            <div class="blog-slider__img">
              <img src={data.photo} alt="" />
            </div>
            <div class="blog-slider__content">
              <span class="blog-slider__code">{data.location}</span>
              <div class="blog-slider__title">{data.title}</div>
              <div class="blog-slider__text">{data.desc}</div>
              <div class="blog-slider__code">
                <p> ₹ {data.price}</p>
                <p> {data.size}</p>
              </div>
              <button class="blog-slider__button">BOOK NOW</button>
            </div>
          </div>
        ))}

       
      </div>
    </div> */}
    <div className="teamnumbers">
        <p>Meet Our Expert Team</p>
        <h2>
        Your Guides to Global Education  <br></br>
        and <span className="highlighted">Admissions</span>
        </h2>
      </div>

 <div className="container">
      <div className="loadbar" style={{ width: `${loadingProgress}%` }}></div>
      <div id="slide" ref={slideRef}>
        {data.map((item) => (
          <div
            key={item.id}
            className="item"
            style={{ backgroundImage: `url(${item.imgUrl})` }}
          >
            <div className="content">
              <div className="name">{item.name}</div>
              <div className="des">{item.desc}</div>
              <button>See more</button>
            </div>
          </div>
        ))}
      </div>
      <div className="buttons">
        <button id="prev" onClick={handleClickPrev}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <button id="next" onClick={handleClickNext}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
      <div className="bottom-nav">
        <div className="b5">
          <div className="locontainer">
            <div className="tags">
              <img src={mfc} alt="image" width="150px" height="60px" />
              
              <div className="Email">
                <p>Email:</p>
                <p>contact@hbicsolutions.in</p>
              </div>
              <div className="Address">
                <p>Address:</p>
                <p>
                  1-8-32/39, Bapu Bagh, Ram Gopalpet Police Station, Rasoolpura,
                  Secunderabad, Telangana -500 003, India.
                </p>
              </div>
              <div>
                <button className="book">Book slot</button>
              </div>
            </div>
          </div>
        </div>
        <div className="totalcontant">
          <div className="totalinner">
            <h2>Links</h2>
            <p>About</p>
            <p>Meet Team</p>
            <p>New & Media</p>
            <p>Our Projects</p>
            <p>contant</p>
          </div>
          <div className="totalinner2">
            <h2>Visa</h2>
            <p>Student visa</p>
            <p>Bussiness visa</p>
            <p>Family Visa</p>
            <p>Travel Visa</p>
            <p>Work Visa</p>
          </div>
          <div className="totalinner3">
            <h2>Services</h2>
            <p>PR Application</p>
            <p>Visa Consultancy</p>
            <p>Travel Insurance</p>
            <p>Work Permits</p>
            <p>Abrod Study</p>
          </div>
          <div className="images">
            <div>
              <h2>Gallery</h2>
              <img src={pport} alt="sc2" width="50px" height="50px" />
              <img src={samko} alt="sc2" width="50px" height="50px" />
              <img src={dart} alt="sc2" width="50px" height="50px" />
            </div>
            <img src={healt} alt="sc2" width="50px" height="50px" />
            <img src={mapp} alt="sc2" width="50px" height="50px" />
            <img src={card} alt="sc2" width="50px" height="50px" />
          </div>
          <div className="totalinner3">
            <h2>Newletters</h2>
            <p>Signup for our latest news & articles.</p>
            <div className="email-option">
         
              <input type="email" placeholder="Enter your email" />
        
              <div className="social-media-icons">
             
                <div className="socon">
                  <a href="https://twitter.com">
                    <img src={twe} alt="Twitter" width="20px" height="20px" />
                  </a>
                </div>
             
                <div className="socon">
                  <a href="https://instagram.com">
                    <img src={ist} alt="Instagram" width="20px" height="20px" />
                  </a>
                </div>
               
                <div className="socon">
                  <a href="https://facebook.com">
                    <img src={face} alt="Facebook" width="15px" height="30px" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>

<nav class="nav">
  <a href="#" class="nav__link">
    <i class="material-icons nav__icon">dashboard</i>
    <span class="nav__text">Dashboard</span>
  </a>
  <a href="#" class="nav__link nav__link--active">
    <i class="material-icons nav__icon">person</i>
    <span class="nav__text">Profile</span>
  </a>
  <a href="#" class="nav__link">
    <i class="material-icons nav__icon">devices</i>
    <span class="nav__text">Devices</span>
  </a>
  <a href="#" class="nav__link">
    <i class="material-icons nav__icon">lock</i>
    <span class="nav__text">Privacy</span>
  </a>
  <a href="#" class="nav__link">
    <i class="material-icons nav__icon">settings</i>
    <span class="nav__text">Settings</span>
  </a>
</nav>

    </div>

  

  

  
   
    
  );
}

export default Home;
