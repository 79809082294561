import {Link } from 'react-router-dom';
import './style.css';
import mfc from "../src/images/mfc_logo.png"
import accomodation from "../src/images/accomodation.png";
import jobs from "../src/images/jobs.png";
import localguide from "../src/images/local_guide.png";
import fb from "../src/images/facebook.png"
import twitter from "../src/images/twitter.png"
import instag from "../src/images/ig.png"
import "./style.css"


const About = () => {
    return (
        <div>
            <nav id="nav1">
              <div className='image'>

              <Link to= "/"><img src={mfc} alt="image" width="150px" height="70px" /></Link>
              </div>
              <div>
                <ul className="list">
                  <li><Link to= "/">Home</Link></li>
                  <li><Link to= "/about">About Us</Link></li>
                  <li><Link to= "/Services">Services</Link></li>
                  <li><Link to= "/Latestoffers">Latest Offers</Link></li>
                  <li><Link to= "/Legal_support">Legal Support</Link></li>
                  <li><Link to= "/Contact">Contact</Link></li>
                </ul>
              </div>
              <div className='button'>
                <h5>Login</h5>
                <button className="btn"><b>+91-40-2939 7263</b></button>
              </div>
            </nav>
            <div className="head2">
              <div className='image2'>
        </div>





        <div className='box'>
          <div className='b1'>
           
            <div className='p1'>
              <h1>Universities</h1>
            </div>
          </div>

          <div className='b2'>
            <div className='head1img'><img src={accomodation} alt="sc1" width="180px" height="160px" />
            </div>
            <div className='p1'>
              <h1>Accomodation</h1>
            </div>
          </div>

          <div className='b3'>
            <div className='head2img'><img src={jobs} alt="sc2" width="180px" height="160px" />
            </div>
            <div className='p1'>
              <h1>Jobs</h1>
            </div>
          </div>

          <div className='b4'>
            <div className='head2img'><img src={localguide} alt="sc2" width="180px" height="160px" />
            </div>
            <div className='p1'>
              <h1>Local Guide</h1>
            </div>
          </div>
          </div>
        </div>

        <nav id="nav2" >
          <div className='last'>
            <img src={mfc} alt="sc" width="150px" height="70px" />
            <p>On the other hand,we denounce </p>
            <p>with righteous indignation and </p>
            <p>dislike men who are so</p>
            <div className="f">
              <div className='i1'><img src={fb} alt="sc" width="55px" height="55px" /></div>
              <div className='i2'><img src={twitter} alt="sc" width="40px" height="40px" /></div>
              <div className='i3'><img src={instag} alt="sc" width="40px" height="40px" /></div>
              <div className='available'>
                <h4>Available POS</h4>
                <div className='food'> <p>Food Delivery</p>
                  <p>Coffee Shop</p>
                  <p>Clothing Store</p>
                  <p>E-Commerce</p> </div></div>

              <div className='company'>
                <h4>Company</h4>
                <div className='features'>
                  <p>Features</p>
                  <p>FAQ</p>
                  <p>Privacy Policy</p>
                  <p>Terms of Use</p>
                </div></div>
              <div className='subscribe'>
                <h4>Subscribe Us</h4>
                <div className='get'>
                  <p> Get Business news,tip and solutions to</p>
                  <p> your problems from our experts.</p>
                </div>
                <input type='email' id="tbemail" placeholder='Enter your email'></input><br></br>
                <input type='button' id="btnsub" value="subscribe"></input>
              </div>
            

            </div>
            <div className='copy'><p>Copyright @ <b>HBIC Solutions</b> 2024</p></div>
          </div>
        </nav>


      </div>
    )

}
export default About