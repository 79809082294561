import React, { useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import './Home.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import mfc from "../src/images/mfc_logo.png";
import fb from "../src/images/facebook.png";
import twitter from "../src/images/twitter.png";
import instag from "../src/images/ig.png";
import youtube from "../src/images/youtube.png";
import land from "../src/images/land.jpg";
import coro from "../src/images/coro.png";
import privacy from "../src/images/privacy.jpg";
import './privacy.css';
function Privacy() {
  


  

  return (
    <div className='nb'>
      <div className='full screen'>
        <div>
          <h2></h2>
          
        </div>
      </div> <div className="nb">
      //here
      <div className="full screen">
        {/* {isMobile ? <p>Mobile Content</p> : <p>Desktop Content</p>} */}
        <div>
          <h2></h2>
          <Slider>
            <div className="image-with-text-container">
              <img src={land} alt="image" width="100%" height="710px" />
              {/* <img src={home1} alt="image" width="100%" height="100%" /> */}
            </div>
            <div className="image-with-text-container">
              <img src={coro} alt="image" width="100%" height="100%" />
            </div>
          </Slider>
        </div>
      </div>
      </div>
      <nav id="nav1">
        <Link to="/">
          <img src={mfc} alt="image" width="150px" height="60px" />
        </Link>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/">About Us</Link></li>
          <li><Link to="/">Services</Link></li>
          <li><Link to="/privacy">privacy</Link></li>
          <li><Link to="/">Legal Support</Link></li>
          <li><Link to="/">Contact</Link></li>
        </ul>
        <div className='button'>
          <Link to="/login" >Login</Link>
          <button className="btn"><b>+91-40-2939 7263</b></button>
        </div>
      </nav>
      <div className="heading"><h1>Privacy Policy</h1></div>
      <div className="matter">
        <p>
          <li>1 ACCEPTANCE TO THE TERMS OF USE</li>
          <li>1.1 This MobileApp is made available Privacy Policy Effective Date: 15-08-2023. This Privacy Policy governs the manner in which Dissertation Support collects, uses, maintains, and discloses information collected from users of the Dissertation Support mobile application Dissertation Support available on the Google Play Store.</li>
          <li>1.2 Personal information Collect information user Provide: When user use app, we may collect personal
          information that user voluntarily provide to us, such as user name, email address, phone number, and other
          relevant information necessary to provide with the services and support. </li>
          <li>1.3 Usage Information: We may automatically collect certain information about user’s interaction with the APP. This includes but is not limited to user’s device information to improve the functionality of our App, Enhance the user experience, and for analytical purposes.</li>
          <li>1.4 Location Information: Our App may collect and use user’s precise location information to provide with localized services such as finding nearby universities, courses, or local guides. We will only collect this information with the user’s explicit consent. </li>
          <li>We may use the information we collect to provide user with the services and support users request, including assisting user in finding universities, courses, and local guides relevant to user needs. We may also personalize the content and recommendations based on user preferences and usage patterns.</li>
          <li>2.1 Communication</li>
          <li>We may use user email address to send important updates, notifications, and newsletters related to our services. User may opt-out of receiving these communications at any time by following the instructions provided in the email or contacting us directly.</li>
          <li>2.1 Analytics and Improvement</li>
          <li>We may use the information we collect to analyze trends, track user behaviour, and improve the performance
          and functionality of our App. This information is used in an aggregated and anonymous form, and no personally
          identifiable information will be disclosed.</li>
        <li>2.2 Legal Compliance</li>
        <li>We may disclose users information if requires to do so by law or in response to a valid legal request. We
          may also disclose user’s information to protect our rights, property, or safety, or the rights, property, or
          safety of others. Information Sharing we understand the importance of user’s privacy and will not sell trade
          or rent users personal information to third parties without users consent, except as described in this Privacy
          Policy. We may share users information with trusted third-party service providers who assist us in operating
          our App, conducting our business, or providing services to users, but only to the extent necessary for those
          purposes. These third-party service providers are required to maintain the confidentiality and security of
          user’s information we collect from unauthorized access, disclosure, alteration, or destruction. Therefore, we
          cannot guarantee absolute security of information. Children’s Privacy our App is not intended for children
          under the age of 13. We don’t knowingly collect personal information from children under 13. If we discover
          that we have collected personal information from a child under 13 without parental consent, we will take
          prompt steps to delete that information from our records. Changes to this privacy policy we may update this
          Privacy policy from time to time. We will notify users of any changes by posting the new Privacy policy on
          this page and updating the effective date at the top. It is user’s responsibility to review this privacy
          Policy periodically for any changes. Contact us if you have any questions or concerns about this Privacy.</li>

          {/* Add more privacy policy content here */}
        </p>
      </div>
      
    </div>
  );
};

export default Privacy;
