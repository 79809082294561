import {Link } from 'react-router-dom';
import './style.css';
import mfc from "../src/images/mfc_logo.png"


const Payment = () => {
    return (
        <div>
            <nav id="nav1">
        <div className='image'>

        <Link to= "/"><img src={mfc} alt="image" width="150px" height="70px" /></Link>
        </div>
        <div>
          <ul className="list">
            <li><Link to= "/">Home</Link></li>
            <li><Link to= "/about">About Us</Link></li>
            <li><Link to= "/Services">Services</Link></li>
            <li><Link to= "/Latestoffers">Latest Offers</Link></li>
            <li><Link to= "/Legal_support">Legal Support</Link></li>
            <li><Link to= "/Contact">Contact</Link></li>
          </ul>
        </div>
        <div className='button'>
          <h5>Login</h5>
          <button className="btn"><b>+91-40-2939 7263</b></button>
        </div>
      </nav>
        </div>
    )

}
export default Payment