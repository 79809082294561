// import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const Onboard = () => {
//   const [course, setCourse] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [editableIndex, setEditableIndex] = useState(null);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await fetch("/get_onbs_form"); // Using relative URL
//       if (!response.ok) {
//         throw new Error("Failed to fetch data");
//       }
//       const data = await response.json();
//       setCourse(data);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setError(error.message);
//       toast.error("Failed to fetch data");
//       setLoading(false);
//     }
//   };

//   // Other functions...

//   // Render content...
// };

// export default Onboard;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Onboardsupport= () => {
  const [personalDetails, setPersonalDetails] = useState("");
  const [pnrNumber, setPnrNumber] = useState("");
  const [extraLegRoom, setExtraLegRoom] = useState("");
  const [seatSelection, setSeatSelection] = useState("");
  const [foodPreferences, setFoodPreferences] = useState("");
  const [religion, setReligion] = useState("");
  const [anySupport, setAnySupport] = useState("");
  const [otherDetails, setOtherDetails] = useState("");
  const [visitingCountry, setVisitingCountry] = useState("");

  const navigate = useNavigate();

  const upload = async () => {
    try {
      const requestData = {
        personal_details: personalDetails,
        pnr_number: pnrNumber,
        extra_leg_room: extraLegRoom,
        seat_selection: seatSelection,
        food_preferences: foodPreferences,
        religion: religion,
        any_support: anySupport,
        other_details: otherDetails,
        visiting_country: visitingCountry
      };

      const response = await fetch("https://api.my-friend.co/onbs_form/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      });

      if (response.ok) {
        toast("Data uploaded successfully!");
        navigate("/Home");
      } else {
        throw new Error("Failed to upload data");
      }
    } catch (error) {
      console.error("Error uploading data:", error);
      toast.error("Failed to upload data");
    }
  };

  return (
    <div className="visa-page">
      <div className="visa-container">
        <h2>Onboard Support</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="visa-input-group">
            <label>Personal Details</label>
            <input
              type="text"
              value={personalDetails}
              onChange={(e) => setPersonalDetails(e.target.value)}
              required
              style={{ width: "400px", height: "300px" }}
            />
          </div>
          <div className="visa-input-group">
            <label>PNR Number</label>
            <input
              type="text"
              value={pnrNumber}
              onChange={(e) => setPnrNumber(e.target.value)}
              required
              style={{ width: "400px", height: "300px" }}
            />
          </div>
          <div className="visa-input-group">
            <label>Extra Leg Room</label>
            <input
              type="text"
              value={extraLegRoom}
              onChange={(e) => setExtraLegRoom(e.target.value)}
              required
              style={{ width: "400px", height: "300px" }}
            />
          </div>
          <div className="visa-input-group">
            <label>Seat Selection</label>
            <input
              type="text"
              value={seatSelection}
              onChange={(e) => setSeatSelection(e.target.value)}
              required
              style={{ width: "400px", height: "300px" }}
            />
          </div>
          <div className="visa-input-group">
            <label>Food Preferences</label>
            <input
              type="text"
              value={foodPreferences}
              onChange={(e) => setFoodPreferences(e.target.value)}
              required
              style={{ width: "400px", height: "300px" }}
            />
          </div>
          <div className="visa-input-group">
            <label>Religion</label>
            <input
              type="text"
              value={religion}
              onChange={(e) => setReligion(e.target.value)}
              required
              style={{ width: "400px", height: "300px" }}
            />
          </div>
          <div className="visa-input-group">
            <label>Any Support</label>
            <input
              type="text"
              value={anySupport}
              onChange={(e) => setAnySupport(e.target.value)}
              required
              style={{ width: "400px", height: "300px" ,width:"200px" }}
            />
          </div>
          <div className="visa-input-group">
            <label>Other Details</label>
            <input
              type="text"
              value={otherDetails}
              onChange={(e) => setOtherDetails(e.target.value)}
              required
              style={{ width: "400px", height: "300px" }}
            />
          </div>
          <div className="visa-input-group">
            <label>Visiting Country</label>
            <select
              value={visitingCountry}
              onChange={(e) => setVisitingCountry(e.target.value)}
              required
              
            >
              <option value="">Select Visiting Country</option>
              <option value="uk">United Kingdom</option>
              <option value="us">United States</option>
              <option value="canada">Canada</option>
              <option value="australia">Australia</option>
              <option value="germany">Germany</option>
              <option value="france">France</option>
            </select>
          </div>
          <button className="visa-button" type="button" onClick={upload}>
            Submit
          </button>
          <p className="visa-user">
            Enter correct details{" "}
            <span>
              <b>Home page</b>
            </span>
          </p>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Onboardsupport;
