import { Link } from 'react-router-dom';
import React from "react";
import Usa from "./images/usa.png"
import Uk from "./images/uk.png"
import Canada from "./images/canada.png"
import Altly from "./images/altly.png"
import Astr from "./images/astr.png"

const Country = () => {
    return (
        <div className='full-screen'>
            <div className='image-container'>
                <div className='image-box'>
                    <Link to="/home">
                        <img src={Usa} alt="USA" className="country-image" />
                    </Link>
                </div>
                <div className='image-box'>
                    <Link to="/home">
                        <img src={Uk} alt="UK" className="country-image" />
                    </Link>
                </div>
                <div className='image-box'>
                    <Link to="/home">
                        <img src={Canada} alt="Canada" className="country-image" />
                    </Link>
                </div>
            </div>
            <div className='image-container'>
                <div className='image-box'>
                    <Link to="/home">
                        <img src={Canada} alt="Canada" className="country-image" />
                    </Link>
                </div>
                <div className='image-box-large'>
                    <Link to="/home">
                        <img src={Altly} alt="Altly" className="country-image" />
                    </Link>
                </div>
                <div className='image-box'>
                    <Link to="/home">
                        <img src={Astr} alt="Astr" className="country-image" />
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default Country;
