// import React, { useState } from 'react';
// import './Profile.css';
// function Profile() {
//     const [formData, setFormData] = useState({
//         username: 'nmaxwell',
//         name: 'Nelle Maxwell',
//         email: 'nmaxwell@mail.com',
//         company: 'Company Ltd.',
//         bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
//         birthday: 'May 3, 1995',
//         country: 'Canada',
//         phone: '+0 (123) 456 7891',
//         website: '',
//         twitter: 'https://twitter.com/user',
//         facebook: 'https://www.facebook.com/user',
//         googlePlus: '',
//         linkedIn: '',
//         instagram: 'https://www.instagram.com/user',
//         emailComments: true,
//         emailForum: true,
//         emailFollows: false,
//         newsAnnouncements: true,
//         weeklyUpdates: false,
//         blogDigest: true,
//     });
//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleCheckboxChange = (e) => {
//         const { name, checked } = e.target;
//         setFormData({ ...formData, [name]: checked });
//     };

//     const handleSaveChanges = () => {
//         // Handle save changes logic here
//         console.log('Form Data:', formData);
//     };

//     const handleCancel = () => {
//         // Handle cancel logic here
//         console.log('Cancel');
//     };

//     return (
//         <div className="container light-style flex-grow-1 container-p-y">
//         <div className="sidebar">
//             <h4 className="font-weight-bold py-3 mb-4">Account settings</h4>
//             <div className="list-group list-group-flush account-settings-links">
//                 <a className="list-group-item list-group-item-action active" data-toggle="list" href="#account-general">General</a>
//                 <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-change-password">Change password</a>
//                 <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-info">Info</a>
//                 <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-social-links">Social links</a>
//                 <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-connections">Connections</a>
//                 <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-notifications">Notifications</a>
//             </div>
//         </div>
                 
//                     <div className="col-md-9"/>
//                         <div className="tab-content"/>
//                             <div className="tab-pane fade active show" id="account-general">
//                                 <div className="card-body media align-items-center">
//                                     <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar" className="d-block ui-w-80" />
//                                     <div className="media-body ml-4">
//                                         <label className="btn btn-outline-primary">
//                                             Upload new photo
//                                             <input type="file" className="account-settings-fileinput" />
//                                         </label> &nbsp;
//                                         <button type="button" className="btn btn-default md-btn-flat">Reset</button>
//                                         <div className="text-light small mt-1">Allowed JPG, GIF or PNG. Max size of 800K</div>
//                                     </div>
//                                 </div>
//                                 <hr className="border-light m-0" />
//                                 <div className="card-body">
//                                     <div className="form-group">
//                                         <label className="form-label">Username</label>
//                                         <input type="text" className="form-control mb-1" value={formData.username} onChange={handleChange} name="username" />
//                                     </div>
//                                     <div className="form-group">
//                                         <label className="form-label">Name</label>
//                                         <input type="text" className="form-control" value={formData.name} onChange={handleChange} name="name" />
//                                     </div>
//                                     <div className="form-group">
//                                         <label className="form-label">E-mail</label>
//                                         <input type="text" className="form-control mb-1" value={formData.email} onChange={handleChange} name="email" />
//                                         <div className="alert alert-warning mt-3">
//                                             Your email is not confirmed. Please check your inbox.<br />
//                                             <a href="javascript:void(0)">Resend confirmation</a>
//                                         </div>
//                                     </div>
//                                     <div className="form-group">
//                                         <label className="form-label">Company</label>
//                                         <input type="text" className="form-control" value={formData.company} onChange={handleChange} name="company" />
//                                     </div>
//                                 </div>
//                             </div>
//                             {/* Rest of the tabs */}
//                             <div class="tab-pane fade" id="account-change-password">
//                             <div class="card-body pb-2">
//                                 <div class="form-group">
//                                     <label class="form-label">Current password</label>
//                                     <input type="password" class="form-control"/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">New password</label>
//                                     <input type="password" class="form-control"/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Repeat new password</label>
//                                     <input type="password" class="form-control"/>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="tab-pane fade" id="account-info">
//                             <div class="card-body pb-2">
//                                 <div class="form-group">
//                                     <label class="form-label">Bio</label>
//                                     <textarea class="form-control"
//                                         rows="5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris nunc arcu, dignissim sit amet sollicitudin iaculis, vehicula id urna. Sed luctus urna nunc. Donec fermentum, magna sit amet rutrum pretium, turpis dolor molestie diam, ut lacinia diam risus eleifend sapien. Curabitur ac nibh nulla. Maecenas nec augue placerat, viverra tellus non, pulvinar risus.</textarea>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Birthday</label>
//                                     <input type="text" class="form-control" value="May 3, 1995"/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Country</label>
//                                     <select class="custom-select">
//                                         <option>USA</option>
//                                         <option selected>Canada</option>
//                                         <option>UK</option>
//                                         <option>Germany</option>
//                                         <option>France</option>
//                                     </select>
//                                 </div>
//                             </div>
//                             <hr class="border-light m-0"/>
//                             <div class="card-body pb-2">
//                                 <h6 class="mb-4">Contacts</h6>
//                                 <div class="form-group">
//                                     <label class="form-label">Phone</label>
//                                     <input type="text" class="form-control" value="+0 (123) 456 7891"/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Website</label>
//                                     <input type="text" class="form-control" value/>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="tab-pane fade" id="account-social-links">
//                             <div class="card-body pb-2">
//                                 <div class="form-group">
//                                     <label class="form-label">Twitter</label>
//                                     <input type="text" class="form-control" value="https://twitter.com/user"/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Facebook</label>
//                                     <input type="text" class="form-control" value="https://www.facebook.com/user"/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Google+</label>
//                                     <input type="text" class="form-control" value/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">LinkedIn</label>
//                                     <input type="text" class="form-control" value/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Instagram</label>
//                                     <input type="text" class="form-control" value="https://www.instagram.com/user"/>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="tab-pane fade" id="account-connections"/>
//                             <div class="card-body">
//                                 <button type="button" class="btn btn-twitter">Connect to
//                                     <strong>Twitter</strong></button>
//                             </div>
//                             <hr class="border-light m-0"/>
//                             <div class="card-body">
//                                 <h5 class="mb-2">
//                                     <a href="javascript:void(0)" class="float-right text-muted text-tiny"><i
//                                             class="ion ion-md-close"></i> Remove</a>
//                                     <i class="ion ion-logo-google text-google"></i>
//                                     You are connected to Google:
//                                 </h5>
//                                 <a href="/cdn-cgi/l/email-protection" class="__cf_email__"
//                                     data-cfemail="f9979498818e9c9595b994989095d79a9694">[email&#160;protected]</a>
//                             </div>
//                             <hr class="border-light m-0"/>
//                             <div class="card-body">
//                                 <button type="button" class="btn btn-facebook">Connect to
//                                     <strong>Facebook</strong></button>
//                             </div>
//                             <hr class="border-light m-0"/>
//                             <div class="card-body">
//                                 <button type="button" class="btn btn-instagram">Connect to
//                                     <strong>Instagram</strong></button>
//                             </div>
                        
//                         <div class="tab-pane fade" id="account-notifications">
//                             <div class="card-body pb-2">
//                                 <h6 class="mb-4">Activity</h6>
//                                 <div class="form-group">
//                                     <label class="switcher">
//                                         <input type="checkbox" class="switcher-input" checked/>
//                                         <span class="switcher-indicator">
//                                             <span class="switcher-yes"></span>
//                                             <span class="switcher-no"></span>
//                                         </span>
//                                         <span class="switcher-label">Email me when someone comments on my article</span>
//                                     </label>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="switcher">
//                                         <input type="checkbox" class="switcher-input" checked/>
//                                         <span class="switcher-indicator">
//                                             <span class="switcher-yes"></span>
//                                             <span class="switcher-no"></span>
//                                         </span>
//                                         <span class="switcher-label">Email me when someone answers on my forum
//                                             thread</span>
//                                     </label>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="switcher">
//                                         <input type="checkbox" class="switcher-input"/>
//                                         <span class="switcher-indicator">
//                                             <span class="switcher-yes"></span>
//                                             <span class="switcher-no"></span>
//                                         </span>
//                                         <span class="switcher-label">Email me when someone follows me</span>
//                                     </label>
//                                 </div>
//                             </div>
//                             <hr class="border-light m-0"/>
//                             <div class="card-body pb-2">
//                                 <h6 class="mb-4">Application</h6>
//                                 <div class="form-group">
//                                     <label class="switcher">
//                                         <input type="checkbox" class="switcher-input" checked/>
//                                         <span class="switcher-indicator">
//                                             <span class="switcher-yes"></span>
//                                             <span class="switcher-no"></span>
//                                         </span>
//                                         <span class="switcher-label">News and announcements</span>
//                                     </label>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="switcher">
//                                         <input type="checkbox" class="switcher-input"/>
//                                         <span class="switcher-indicator">
//                                             <span class="switcher-yes"></span>
//                                             <span class="switcher-no"></span>
//                                         </span>
//                                         <span class="switcher-label">Weekly product updates</span>
//                                     </label>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="switcher">
//                                         <input type="checkbox" class="switcher-input" checked/>
//                                         <span class="switcher-indicator">
//                                             <span class="switcher-yes"></span>
//                                             <span class="switcher-no"></span>
//                                         </span>
//                                         <span class="switcher-label">Weekly blog digest</span>
//                                     </label>
//                                 </div>
//                             </div>
//                         </div>       
//             <div className="text-right mt-3">
//                 <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>Save changes</button>&nbsp;
//                 <button type="button" className="btn btn-default" onClick={handleCancel}>Cancel</button>
//             </div>
//        </div>
//     );
// }
// export default Profile;
// import React, { useState } from 'react';
// import './Profile.css';

// function Profile() {
//     const [formData, setFormData] = useState({
//         username: 'nmaxwell',
//         name: 'Nelle Maxwell',
//         email: 'nmaxwell@mail.com',
//         company: 'Company Ltd.',
//         bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
//         birthday: 'May 3, 1995',
//         country: 'Canada',
//         phone: '+0 (123) 456 7891',
//         website: '',
//         twitter: 'https://twitter.com/user',
//         facebook: 'https://www.facebook.com/user',
//         googlePlus: '',
//         linkedIn: '',
//         instagram: 'https://www.instagram.com/user',
//         emailComments: true,
//         emailForum: true,
//         emailFollows: false,
//         newsAnnouncements: true,
//         weeklyUpdates: false,
//         blogDigest: true,
//     });

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleCheckboxChange = (e) => {
//         const { name, checked } = e.target;
//         setFormData({ ...formData, [name]: checked });
//     };

//     const handleSaveChanges = () => {
//         // Handle save changes logic here
//         console.log('Form Data:', formData);
//     };

//     const handleCancel = () => {
//         // Handle cancel logic here
//         console.log('Cancel');
//     };

//     return (
//         <div className="container light-style flex-grow-1 container-p-y">
//     <div className="row">
//         <div className="col-md-3 pt-0">
//             <div className="list-group list-group-flush account-settings-links">
//                 <a className="list-group-item list-group-item-action active" data-toggle="list" href="#account-general">General</a>
//                 <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-change-password">Change password</a>
//                 <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-info">Info</a>
//                 <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-social-links">Social links</a>
//                 <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-connections">Connections</a>
//                 <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-notifications">Notifications</a>
//             </div>
//         </div>
//         <div className="col-md-9">
//             <div className="tab-content">
//                 <div className="tab-pane fade active show" id="account-general">
//                             {/* Content for General tab */}
//                             <div className="card-body media align-items-center">
//                                     <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar" className="d-block ui-w-80" />
//                                     <div className="media-body ml-4">
//                                         <label className="btn btn-outline-primary">
//                                             Upload new photo
//                                             <input type="file" className="account-settings-fileinput" />
//                                         </label> &nbsp;
//                                         <button type="button" className="btn btn-default md-btn-flat">Reset</button>
//                                         <div className="text-light small mt-1">Allowed JPG, GIF or PNG. Max size of 800K</div>
//                                     </div>
//                                 </div>
//                                 <hr className="border-light m-0" />
//                                 <div className="card-body">
//                                     <div className="form-group">
//                                         <label className="form-label">Username</label>
//                                         <input type="text" className="form-control mb-1" value={formData.username} onChange={handleChange} name="username" />
//                                     </div>
//                                     <div className="form-group">
//                                         <label className="form-label">Name</label>
//                                         <input type="text" className="form-control" value={formData.name} onChange={handleChange} name="name" />
//                                     </div>
//                                     <div className="form-group">
//                                         <label className="form-label">E-mail</label>
//                                         <input type="text" className="form-control mb-1" value={formData.email} onChange={handleChange} name="email" />
//                                         <div className="alert alert-warning mt-3">
//                                             Your email is not confirmed. Please check your inbox.<br />
//                                             <a href="javascript:void(0)">Resend confirmation</a>
//                                         </div>
//                                     </div>
//                                     <div className="form-group">
//                                         <label className="form-label">Company</label>
//                                         <input type="text" className="form-control" value={formData.company} onChange={handleChange} name="company" />
//                                     </div>
//                                 </div>
                            
//                         </div>
//                         <div className="tab-pane fade" id="account-change-password">
//                             {/* Content for Change Password tab */}
                            
//                             <div class="card-body pb-2">
//                                 <div class="form-group">
//                                     <label class="form-label">Current password</label>
//                                     <input type="password" class="form-control"/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">New password</label>
//                                     <input type="password" class="form-control"/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Repeat new password</label>
//                                     <input type="password" class="form-control"/>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="tab-pane fade" id="account-info">
//                             {/* Content for Info tab */}
//                             <div class="card-body pb-2">
//                                 <div class="form-group">
//                                     <label class="form-label">Bio</label>
//                                     <textarea class="form-control"
//                                         rows="5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris nunc arcu, dignissim sit amet sollicitudin iaculis, vehicula id urna. Sed luctus urna nunc. Donec fermentum, magna sit amet rutrum pretium, turpis dolor molestie diam, ut lacinia diam risus eleifend sapien. Curabitur ac nibh nulla. Maecenas nec augue placerat, viverra tellus non, pulvinar risus.</textarea>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Birthday</label>
//                                     <input type="text" class="form-control" value="May 3, 1995"/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Country</label>
//                                     <select class="custom-select">
//                                         <option>USA</option>
//                                         <option selected>Canada</option>
//                                         <option>UK</option>
//                                         <option>Germany</option>
//                                         <option>France</option>
//                                     </select>
//                                 </div>
//                             </div>
//                             <hr class="border-light m-0"/>
//                             <div class="card-body pb-2">
//                                 <h6 class="mb-4">Contacts</h6>
//                                 <div class="form-group">
//                                     <label class="form-label">Phone</label>
//                                     <input type="text" class="form-control" value="+0 (123) 456 7891"/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Website</label>
//                                     <input type="text" class="form-control" value/>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="tab-pane fade" id="account-social-links">
//                             {/* Content for Social Links tab */}
//                             <div class="card-body pb-2">
//                                 <div class="form-group">
//                                     <label class="form-label">Twitter</label>
//                                     <input type="text" class="form-control" value="https://twitter.com/user"/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Facebook</label>
//                                     <input type="text" class="form-control" value="https://www.facebook.com/user"/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Google+</label>
//                                     <input type="text" class="form-control" value/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">LinkedIn</label>
//                                     <input type="text" class="form-control" value/>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="form-label">Instagram</label>
//                                     <input type="text" class="form-control" value="https://www.instagram.com/user"/>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="tab-pane fade" id="account-connections">
//                             {/* Content for Connections tab */}
//                             <div class="card-body">
//                                 <button type="button" class="btn btn-twitter">Connect to
//                                     <strong>Twitter</strong></button>
//                             </div>
//                             <hr class="border-light m-0"/>
//                             <div class="card-body">
//                                 <h5 class="mb-2">
//                                     <a href="javascript:void(0)" class="float-right text-muted text-tiny"><i
//                                             class="ion ion-md-close"></i> Remove</a>
//                                     <i class="ion ion-logo-google text-google"></i>
//                                     You are connected to Google:
//                                 </h5>
//                                 <a href="/cdn-cgi/l/email-protection" class="__cf_email__"
//                                     data-cfemail="f9979498818e9c9595b994989095d79a9694">[email&#160;protected]</a>
//                             </div>
//                             <hr class="border-light m-0"/>
//                             <div class="card-body">
//                                 <button type="button" class="btn btn-facebook">Connect to
//                                     <strong>Facebook</strong></button>
//                             </div>
//                             <hr class="border-light m-0"/>
//                             <div class="card-body">
//                                 <button type="button" class="btn btn-instagram">Connect to
//                                     <strong>Instagram</strong></button>
//                             </div>
                        
//                         </div>
//                         <div className="tab-pane fade" id="account-notifications">
//                             {/* Content for Notifications tab */}
//                             <div class="card-body pb-2">
//                                 <h6 class="mb-4">Activity</h6>
//                                 <div class="form-group">
//                                     <label class="switcher">
//                                         <input type="checkbox" class="switcher-input" checked/>
//                                         <span class="switcher-indicator">
//                                             <span class="switcher-yes"></span>
//                                             <span class="switcher-no"></span>
//                                         </span>
//                                         <span class="switcher-label">Email me when someone comments on my article</span>
//                                     </label>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="switcher">
//                                         <input type="checkbox" class="switcher-input" checked/>
//                                         <span class="switcher-indicator">
//                                             <span class="switcher-yes"></span>
//                                             <span class="switcher-no"></span>
//                                         </span>
//                                         <span class="switcher-label">Email me when someone answers on my forum
//                                             thread</span>
//                                     </label>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="switcher">
//                                         <input type="checkbox" class="switcher-input"/>
//                                         <span class="switcher-indicator">
//                                             <span class="switcher-yes"></span>
//                                             <span class="switcher-no"></span>
//                                         </span>
//                                         <span class="switcher-label">Email me when someone follows me</span>
//                                     </label>
//                                 </div>
//                             </div>
//                             <hr class="border-light m-0"/>
//                             <div class="card-body pb-2">
//                                 <h6 class="mb-4">Application</h6>
//                                 <div class="form-group">
//                                     <label class="switcher">
//                                         <input type="checkbox" class="switcher-input" checked/>
//                                         <span class="switcher-indicator">
//                                             <span class="switcher-yes"></span>
//                                             <span class="switcher-no"></span>
//                                         </span>
//                                         <span class="switcher-label">News and announcements</span>
//                                     </label>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="switcher">
//                                         <input type="checkbox" class="switcher-input"/>
//                                         <span class="switcher-indicator">
//                                             <span class="switcher-yes"></span>
//                                             <span class="switcher-no"></span>
//                                         </span>
//                                         <span class="switcher-label">Weekly product updates</span>
//                                     </label>
//                                 </div>
//                                 <div class="form-group">
//                                     <label class="switcher">
//                                         <input type="checkbox" class="switcher-input" checked/>
//                                         <span class="switcher-indicator">
//                                             <span class="switcher-yes"></span>
//                                             <span class="switcher-no"></span>
//                                         </span>
//                                         <span class="switcher-label">Weekly blog digest</span>
//                                     </label>
//                                 </div>
//                             </div>
//                         </div>       
            
//                     </div>
//                     <div className="text-right mt-3">
//                         <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>Save changes</button>&nbsp;
//                         <button type="button" className="btn btn-default" onClick={handleCancel}>Cancel</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Profile;
// import React from 'react';
// import "./Profile.css";

// function Profile() {
    
//     return (
//         <div class="container light-style flex-grow-1 container-p-y">
//             <h4 class="font-weight-bold py-3 mb-4">
//                 Account settings
//             </h4>
//             <div className='all'>
//                 <div class="card overflow-hidden">
//                     <div class="row no-gutters row-bordered row-border-light">
//                         <div class="col-md-3 pt-0">
//                             <div class="list-group list-group-flush account-settings-links">
//                                 <div className='item'>
//                                     <li><a class="list-group-item list-group-item-action active" data-toggle="list" href="#account-general">General</a></li>
//                                     <li><a class="list-group-item list-group-item-action" data-toggle="list" href="#account-change-password">Change password</a></li>
//                                     <li><a class="list-group-item list-group-item-action" data-toggle="list" href="#account-info">Info</a></li>
//                                     <li><a class="list-group-item list-group-item-action" data-toggle="list" href="#account-social-links">Social links</a></li>
//                                     <li><a class="list-group-item list-group-item-action" data-toggle="list" href="#account-connections">Connections</a></li>
//                                     <li><a class="list-group-item list-group-item-action" data-toggle="list" href="#account-notifications">Notifications</a></li>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="col-md-9">
//                             <div class="tab-content">
//                                 <div class="tab-pane fade active show" id="account-general">
//                                     <div class="card-body media align-items-center">
//                                         <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt
//                                             class="d-block ui-w-80"/>
//                                         <div class="media-body ml-4">
//                                             <label class="btn btn-outline-primary">
//                                                 Upload new photo
//                                                 <input type="file" class="account-settings-fileinput"/>
//                                             </label> &nbsp;
//                                             <button type="button" class="btn btn-default md-btn-flat">Reset</button>
//                                             <div class="text-light small mt-1">Allowed JPG, GIF or PNG. Max size of 800K</div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 {/* Add other tab panes */}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 {/* Add Save and Cancel buttons */}
//                 <div class="text-right mt-3">
//                     <button type="button" class="btn btn-primary">Save changes</button>&nbsp;
//                     <button type="button" class="btn btn-default">Cancel</button>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Profile;

// Profile.js

// Profile.js
// Profile.js
// Profile.jsx

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Profile.css";

const Profile = () => {
  const location = useLocation();
  const { userId } = location.state || {}; // Get the userId from location state
  console.log(location.state)
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editableIndex, setEditableIndex] = useState(null);
  const [profilePic, setProfilePic] = useState(null);

  useEffect(() => {
    if (userId) {
      fetchData();
    } else {
      setError("User ID not found");
      setLoading(false);
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      const response = await fetch(`https://api.my-friend.co/register/get/${userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const userData = await response.json();
      setUserData(userData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError(error.message);
      toast.error("Failed to fetch user data");
      setLoading(false);
    }
  };

  // Function to handle edit action for a row
  const handleEdit = (index) => {
    setEditableIndex(index);
  };

  // Function to handle save action for a row
  const handleSave = async (index) => {
    try {
      const updatedRow = userData[index];
      const response = await fetch(`https://api.my-friend.co/register/update/${updatedRow.id}/`, {
        method: "PUT", // or "POST"
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedRow),
      });
      if (!response.ok) {
        throw new Error("Failed to save data");
      }
      setEditableIndex(null); // Disable editing after saving
      toast.success("Data updated successfully");
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Failed to save data");
    }
  };

  // Function to handle profile picture upload
  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Perform validation if needed
      setProfilePic(file);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!userData) {
    return <div>No user data available.</div>;
  }

  return (
    <div className="profile-container">
      <h2>Profile</h2>
      <div className="upload-container">
        <input type="file" accept="image/*" onChange={handleProfilePicChange} id="profile-pic-input" />
        <label htmlFor="profile-pic-input">Upload Profile Picture</label>
      </div>
      {userData.map((row, index) => (
        <div className="profile-card" key={index}>
          <div className="profile-card-header">
            <h3>{`Row ${index + 1}`}</h3>
            {editableIndex === index ? (
              <button onClick={() => handleSave(index)}>Save</button>
            ) : (
              <button onClick={() => handleEdit(index)}>Edit</button>
            )}
          </div>
          <div className="profile-card-content">
            {Object.entries(row).map(([fieldName, fieldValue]) => (
              <div className="profile-card-field" key={fieldName}>
                <label>{fieldName}</label>
                {editableIndex === index ? (
                  <input
                    type="text"
                    value={fieldValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      setUserData((prevUserData) => {
                        const updatedUserData = [...prevUserData];
                        updatedUserData[index][fieldName] = value;
                        return updatedUserData;
                      });
                    }}
                  />
                ) : (
                  <div>{fieldValue}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Profile;



